<div class="d-lg-block d-none">
  <fgb-banner [title]="'rewards.shipping.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container">
  <div class="card d-flex align-items-center justify-content-center">
    <p class="font-size-14 mb-0 mt-lg-3 mt-2" [innerHTML]="'rewards.shipping.page.card.text' | translate | markdownTranslate"></p>
  </div>
</div>
