<div [class.row]="isList">
  <ng-container *ngIf="marketplaceItems$ | async as marketplaceItems; else loadingRef">
    <ng-container *ngIf="filteredMarketplaceItems.length; else noMarketplaceItems">
      <div *ngFor="let item of filteredMarketplaceItems" [class.col-lg-4]="isList" [class.col-md-6]="isList">
        <div class="loyalty-item-container">
          <fgb-marketplace-list-item [marketplaceItem]="item"></fgb-marketplace-list-item>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- No results -->
<ng-template #noMarketplaceItems>
  <!--Marketplace Placeholder-->
  <div class="no-items w-100 text-center" *ngIf="marketplaceItemView">
    <ng-container *ngIf="googleIcons; else basicIconMarketplacePlaceholder">
      <div class="icon material-icons text-grey no-results-placeholder-icon text-center">shopping_bag</div>
    </ng-container>
    <ng-template #basicIconMarketplacePlaceholder>
      <div
        class="icon svg no-results-placeholder-icon font-weight-bold text-center"
        [inlineSVG]="'assets/images/placeholders/no-marketplace.svg'"
      ></div>
    </ng-template>
    <div class="no-results-placeholder-title font-weight-bold text-primary">
      {{ 'marketplace.placeholder.title' | translate }}
    </div>
    <div class="no-results-placeholder-text text-info mb-5">{{ 'marketplace.placeholder.description' | translate }}</div>
  </div>
  <!--Events Placeholder-->
  <div class="no-items w-100 text-center" *ngIf="eventItemView">
    <ng-container *ngIf="googleIcons; else basicIconEventPlaceholder">
      <div class="icon material-icons text-grey no-results-placeholder-icon text-center">local_activity</div>
    </ng-container>
    <ng-template #basicIconEventPlaceholder>
      <div
        class="icon svg no-results-placeholder-icon font-weight-bold text-center"
        [inlineSVG]="'assets/images/placeholders/no-events.svg'"
      ></div>
    </ng-template>
    <div class="no-results-placeholder-title font-weight-bold text-primary text-uppercase">
      {{ 'events.placeholder.title' | translate }}
    </div>
    <div class="no-results-placeholder-text text-info mb-5">{{ 'events.placeholder.description' | translate }}</div>
  </div>
  <!--Product Placeholder-->
  <div class="no-items w-100 text-center" *ngIf="productsItemView">
    <ng-container *ngIf="googleIcons; else basicIconProductPlaceholder">
      <div class="icon material-icons text-grey no-results-placeholder-icon text-center">local_atm</div>
    </ng-container>
    <ng-template #basicIconProductPlaceholder>
      <div
        class="icon svg no-results-placeholder-icon font-weight-bold text-center"
        [inlineSVG]="'assets/images/placeholders/no-events.svg'"
      ></div>
    </ng-template>
    <div class="no-results-placeholder-title font-weight-bold text-primary text-uppercase">
      {{ 'products.placeholder.title' | translate }}
    </div>
    <div class="no-results-placeholder-text text-info mb-5">{{ 'products.placeholder.description' | translate }}</div>
  </div>
</ng-template>

<!-- Loading -->
<ng-template #loadingRef>
  <div class="w-100">
    <fgbcl-loading></fgbcl-loading>
  </div>
</ng-template>
