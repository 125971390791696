<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <fgbcl-errors></fgbcl-errors>
  <div class="form-group font-weight-bold input-info text-left">
    <label class="field-title mb-2 font-weight-bold" for="username">{{ 'login.page.label.email' | translate }}</label>
    <input
      type="text"
      formControlName="username"
      placeholder="Insert email here"
      class="form-control border-0 font-size-14 bg-muted pl-2 mb-1"
      [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
    />
    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
      <div class="alert-danger" *ngIf="f.username.errors.required">{{ 'login.page.required' | translate }}</div>
    </div>
  </div>

  <div class="form-group input-info font-weight-bold text-left">
    <label class="field-title password-field mb-2 mt-0 mt-lg-0 font-weight-bold text-left" for="password">{{
      'login.page.label.password' | translate
    }}</label>
    <input
      type="password"
      formControlName="password"
      placeholder="Insert password here"
      class="form-control border-0 font-size-14 bg-muted pl-2 mb-1"
      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
    />

    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
      <div class="alert-danger" *ngIf="f.password.errors.required">{{ 'login.page.required' | translate }}</div>
    </div>
  </div>

  <div class="form-group mb-2 mt-4 mt-lg-0">
    <button [disabled]="loading" class="btn btn-success btn-block login-button font-size-14 w-100 font-weight-bold">
      <span *ngIf="!loading">Login</span>
      <fgbcl-loading [size]="'small'" *ngIf="loading"></fgbcl-loading>
    </button>
  </div>
  <div class="panel-text forgot-password text-center font-size-12 font-weight-bold mt-3">
    <a class="forgot-pw" href="https://www.mlb.com/forgot-password">{{ 'login.page.forgot.pw' | translate }}</a>
  </div>
  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
    <div class="form-group received-group mt-3 mt-lg-5">
      <a [routerLink]="['/register/partner']">
        <button class="btn btn-primary btn-block received-button w-100 font-weight-bold">
          {{ 'login.page.recivied.partner' | translate }}
        </button></a
      >
    </div>
  </ng-container>
</form>
