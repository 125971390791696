import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BalanceQuery } from '@fgb/core';
import { config } from '@fgb/core';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';

@Component({
  selector: 'fgb-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss'],
})
export class PointsSummaryComponent implements OnInit {
  googleIcons = IconSetting.googleIcon;
  pointsBalance$: Observable<number | undefined>;

  constructor(private balanceQuery: BalanceQuery) {}

  ngOnInit() {
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
  }
}
