<ul [ngStyle]="!isMarginBottom ? { 'margin-bottom': '0px' } : null" class="nav nav-secondary account-details-nav">
  <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <!--Account Icon Switch-->
    <div class="icon material-icons">person</div>
    <label>{{ 'account.nav.profile' | translate }}</label>
  </a>
  <a class="nav-item" [routerLink]="['/activity']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <!--Activity Icon Switch-->
    <div class="icon material-icons">history</div>
    <label>{{ 'account.nav.activity' | translate }}</label>
  </a>
  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
    <ng-container *inProductCode="productCode.NoPartners">
      <ng-container *notInProductCode="productCode.ExcludedPartners">
        <a
          class="nav-item"
          [routerLink]="['/partners']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="icon material-icons">people</div>
          <label>{{ 'account.nav.partners' | translate }}</label>
        </a>
        <a
          class="nav-item"
          [routerLink]="['/pointsmanagement']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="icon material-icons">send</div>
          <label>{{ 'account.nav.pointsmanagement' | translate }}</label>
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</ul>
