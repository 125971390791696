import { Component, OnInit } from '@angular/core';
import { AddressBook, AddressBookQuery, AddressBookService, ErrorStateService } from '@fgb/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fgb-contacts-management',
  templateUrl: './contacts-management.component.html',
  styleUrls: ['./contacts-management.component.scss'],
})
export class ContactsManagementComponent implements OnInit {
  addressBook$: Observable<AddressBook[]>;
  addNew: boolean = false;
  contactId: number;
  fullName: string;
  email: string;

  constructor(
    private errorStateService: ErrorStateService,
    private addressBookQuery: AddressBookQuery,
    private addressService: AddressBookService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.getAddressBookList();
  }

  getAddressBookList() {
    this.addressBook$ = this.addressBookQuery
      .selectAllContacts()
      .pipe(map((contactsList) => contactsList.sort((a, b) => a.FullName.localeCompare(b.FullName))));
  }

  addContact() {
    this.addressService
      .add(this.fullName, this.email)
      .toPromise()
      .then(() => this.modalService.dismissAll())
      .catch((error) => {
        this.errorStateService.addError(error);
        setTimeout(() => this.errorStateService.clearErrors(), 3000);
      });
  }

  removeContact(id: number) {
    this.addressService.remove(id).toPromise();
    this.modalService.dismissAll();
  }

  add(content: any) {
    this.fullName = '';
    this.email = '';
    this.open(content);
  }

  edit(item: AddressBook, content: any) {
    this.contactId = item.Id === undefined ? 0 : item.Id;
    this.fullName = item.FullName;
    this.email = item.EmailAddress;
    this.open(content);
  }

  updateContact() {
    this.addressService
      .update(this.contactId, this.fullName, this.email)
      .toPromise()
      .then(() => this.modalService.dismissAll())
      .catch((error) => {
        this.errorStateService.addError(error);
        setTimeout(() => this.errorStateService.clearErrors(), 3000);
      });
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then(
      (result) => {},
      (reason) => {}
    );
  }
}
