<ng-container [ngSwitch]="survey.SurveyType">
  <ng-container *ngSwitchCase="surveyType.Poll">
    <fgb-survey-item-poll [survey]="survey"></fgb-survey-item-poll>
  </ng-container>
  <ng-container *ngSwitchCase="surveyType.Predictor">
    <fgb-survey-item-predictor [survey]="survey"></fgb-survey-item-predictor>
  </ng-container>
  <ng-container *ngSwitchCase="surveyType.Trivia">
    <fgb-survey-item-quiz [survey]="survey"></fgb-survey-item-quiz>
  </ng-container>
</ng-container>
