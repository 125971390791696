<div class="d-none d-lg-block">
  <fgb-banner [title]="'points.management.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3"><fgb-member-card></fgb-member-card></div>
<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="instructions">
    <h4 class="instructions-info-title">{{ 'point.transfer.instructions.info.title' | translate }}</h4>
    <div class="font-size-14" [innerHTML]="'point.transfer.instructions.info' | translate | markdownTranslate"></div>
    <fgb-points-transfer> </fgb-points-transfer>
  </div>
</div>
