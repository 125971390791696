import { Component, OnInit, Input } from '@angular/core';
import { SurveyAnswer } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item-answer-completed-poll-item',
  templateUrl: './survey-completed-poll-item.component.html',
  styleUrls: ['./survey-completed-poll-item.component.scss', '../../../../survey.scss']
})
export class SurveyCompletedPollItemComponent implements OnInit {
  @Input() answer: SurveyAnswer;
  @Input() entryCount: number;
  percentage: number;

  constructor() { }

  ngOnInit() {
    this.percentage = Math.round((this.answer.AnswerCount / this.entryCount) * 100);
    if(isNaN(this.percentage)){
      this.percentage = 0;
    }
  }

}
