import { Component, OnInit, Input } from '@angular/core';
import { MarketplaceItemExtended, MarketplaceItemStatus } from '@fgb/core';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';

@Component({
  selector: 'fgb-marketplace-list-item',
  templateUrl: './marketplace-list-item.component.html',
  styleUrls: ['./marketplace-list-item.component.scss'],
})
export class MarketplaceListItemComponent implements OnInit {
  @Input() marketplaceItem: MarketplaceItemExtended;
  @Input() ignoreLink: boolean = false;
  theme: string;
  googleIcons = IconSetting.googleIcon;
  statusText: string;

  constructor() {}

  ngOnInit() {
    this._updateTheme();
    this._updateStatusText();
  }

  private _updateStatusText() {
    switch (this.marketplaceItem.Status) {
      case MarketplaceItemStatus.SoldOut:
        this.statusText = 'marketplace.redeem.sold.out';
        break;
      case MarketplaceItemStatus.NotEnoughPoints:
        this.statusText = 'marketplace.redeem.not.enough.points';
        break;
      case MarketplaceItemStatus.ComingSoon:
        this.statusText = 'marketplace.redeem.coming.soon';
        break;
      case MarketplaceItemStatus.MemberLimitReached:
        this.statusText = 'marketplace.redeem.member.limit';
        break;
      case MarketplaceItemStatus.AllInBasket:
        this.statusText = 'marketplace.redeem.all.in.basket';
      default:
        if (this.marketplaceItem.theme === 'mp-event' || this.marketplaceItem.theme === 'mp-virtual-event') {
          this.statusText = 'marketplace.redeem.event';
        } else if (this.marketplaceItem.theme === 'mp-product') {
          this.statusText = 'marketplace.redeem.product';
        } else {
          this.statusText = 'marketplace.redeem.marketplace';
        }
    }
  }

  private _updateTheme() {
    if (this.marketplaceItem.Status === MarketplaceItemStatus.Available) {
      this.theme = this.marketplaceItem.theme;
    } else {
      this.theme = 'disabled';
    }
  }
}
