import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-contacts-page',
  templateUrl: './contacts-page.component.html',
  styleUrls: ['./contacts-page.component.scss']
})
export class ContactsPageComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
