import { Component, OnInit, Input } from '@angular/core';
import { MemberQuery, TransactionType } from '@fgb/core';

@Component({
  selector: 'fgb-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss'],
})
export class TransactionHistoryComponent implements OnInit {
  constructor(private memberQuery: MemberQuery) {}

  transactionStartDate: Date;
  transactionEndDate: Date;
  transactionType: TransactionType;

  @Input() memberId: string;
  @Input() transactionTypes: TransactionType[];
  @Input() ecashView: boolean;
  @Input() accountView: boolean;

  selectedValues: {
    start: Date;
    end: Date;
    type: TransactionType;
  };

  buttonNames = {
    [TransactionType.ecash]: 'Ecash History',
    [TransactionType.loyalty]: 'Points History',
    [TransactionType.payment]: 'Card History',
  };

  ngOnInit() {
    this.transactionType = this.transactionTypes[0];
    this.memberId = this.memberId || this.memberQuery.getUserContext().memberId;
    this.transactionEndDate = this.transactionEndDate || new Date();
    this.transactionStartDate = new Date();
    this.transactionStartDate.setDate(new Date(this.transactionStartDate).getDate() - 1);
  }

  setTransactionType(type: TransactionType) {
    this.transactionType = type;
  }

  search() {
    this.transactionStartDate.setHours(0, 0, 0, 0);
    this.transactionEndDate.setHours(23, 59, 59, 999);

    this.selectedValues = {
      start: this.transactionStartDate,
      end: this.transactionEndDate,
      type: this.transactionType,
    };
  }
}
