import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fgb-points-summary-bar',
  templateUrl: './points-summary-bar.component.html',
  styleUrls: ['./points-summary-bar.component.scss'],
})
export class PointsSummaryBarComponent implements OnInit {
  @Input() backButtonLink: string = '/rewards/marketplace';
  @Input() backButtonTitle: string;
  @Input() marketplaceItemGroup: string;

  constructor() {}

  ngOnInit() {}
}
