<h3 *ngIf="ecashView" class="text-black mb-4 d-lg-block d-none">
  {{ 'payment.history.page.title' | translate }}
</h3>

<div class="card b-0 mt-3 mt-lg-0">
  <div class="card-body p-3">
    <div class="d-flex flex-column flex-lg-row justify-content-between">
      <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      <button type="button" class="search-button font-weight-bold mb-2 d-block border-0 text-white bg-black" (click)="search()">
        Search
      </button>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>

<ng-template #noSearch>
  <div>
    <div *ngIf="accountView" class="icon material-icons text-grey w-100 text-center">history</div>
    <h5 *ngIf="accountView" class="text-primary text-center my-0 font-weight-bold text-uppercase">
      {{ 'account.nav.activity' | translate }}
    </h5>
    <p class="text-info text-center font-size-12">{{ 'no.activity.placeholder.description' | translate }}</p>
  </div>
</ng-template>
