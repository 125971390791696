import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartnerService, ErrorStateService } from '@fgb/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-partner-accept-form',
  templateUrl: './partner-accept-form.component.html',
  styleUrls: ['./partner-accept-form.component.scss'],
})
export class PartnerAcceptFormComponent implements OnInit {
  acceptForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private partnerService: PartnerService,
    private errorService: ErrorStateService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.acceptForm = this.fb.group({
      code: ['', Validators.required],
      secretCode: ['', Validators.required],
    });
  }

  submit(): void {
    const code: string = this.acceptForm.value.code;
    const secretCode: string = this.acceptForm.value.secretCode;
    this.partnerService
      .confirmPartner(code, secretCode)
      .toPromise()
      .then(() => this.closeModal());
  }

  closeModal(): void {
    this.activeModal.close();
  }

  close(): void {
    this.errorService.clearErrors();
    this.closeModal();
  }
}
