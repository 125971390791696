import { Component, OnInit } from '@angular/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-auctions-page',
  templateUrl: './auctions-page.component.html',
  styleUrls: ['./auctions-page.component.scss'],
})
export class AuctionsPageComponent implements OnInit {
  screenType = ScreenType;
  constructor() {}

  ngOnInit() {}
}
