import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AuthService,
  BalanceQuery,
  Card,
  CardQuery,
  config,
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  NotificationCenterService,
  NotificationCenterQuery,
} from '@fgb/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';
import { ProductCode } from 'src/app/shared/utilities/product-code';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit, OnDestroy {
  googleIcons = IconSetting.googleIcon;
  memberDetails$: Observable<MemberDetails | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  selectedMember: string;
  pointsBalance$: Observable<number | undefined>;
  cards$: Observable<Card[]>;
  unseenNotificationCount$: Observable<number>;
  productCode = ProductCode;
  constructor(
    private authService: AuthService,
    private router: Router,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private balanceQuery: BalanceQuery,
    private notificationService: NotificationCenterService,
    private notificationQuery: NotificationCenterQuery
  ) {}
  private routerSubscription: Subscription;
  public isCollapsed = true;

  ngOnInit() {
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.initMemberAccounts();
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(() => (this.isCollapsed = true))
      )
      .subscribe();
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;
  }

  initMemberAccounts() {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.cards$ = this.cardQuery.selectCards();
    this.selectedMember = this.memberQuery.getUserContext().memberId;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  logout() {
    this.authService
      .logout()
      .toPromise()
      .then(() => this.router.navigate(['/login']));
  }

  loginWithCard(memberId: string) {
    this.authService.switchUser(memberId).toPromise();
  }

  toggleNotificationCenter(open: boolean) {
    this.notificationService.updateIsOpen(open);
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
}
