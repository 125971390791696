import { Component, OnInit } from '@angular/core';
import { ChildQuery, ChildService, ChildViewModel, PaymentOptionType } from '@fgb/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'fgb-guardian-page',
  templateUrl: './guardian-page.component.html',
  styleUrls: ['./guardian-page.component.scss'],
})
export class GuardianPageComponent implements OnInit {
  public childLimit: number = 10;
  public emptyChildren: any[];
  isGuardian: boolean;

  children$: Observable<ChildViewModel[]>;
  freeChildren: ChildViewModel[];

  constructor(private childQuery: ChildQuery, private childService: ChildService) {}

  ngOnInit() {
    this.children$ = this.childQuery.selectChildren().pipe(
      tap((x) => {
        this.emptyChildren = new Array(this.childLimit - x.length);
        this.freeChildren = x.filter((y) => y.MembershipOption.PaymentType === PaymentOptionType.Free);
      })
    );
    this.isGuardian = this.childQuery.isGuardian();
  }

  addChildren() {
    console.log('adding children not implemented');
  }

  becomeGuardian(): void {
    this.childService
      .registerAsGuardian()
      .toPromise()
      .then(() => (this.isGuardian = true));
  }
}
