import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-auction-detail-page',
  templateUrl: './auction-detail-page.component.html',
  styleUrls: ['./auction-detail-page.component.scss'],
})
export class AuctionDetailPageComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  id: number;
  screenType = ScreenType;
  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
  }
}
