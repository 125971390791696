import { Component, OnInit, Input } from '@angular/core';
import { config, ChildViewModel, AuthService, MembershipOptionQuery, MembershipOption, BalanceQuery } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ChildBarcodesCarouselComponent } from '../child-barcodes-carousel/child-barcodes-carousel.component';

@Component({
  selector: 'fgb-child-panel',
  templateUrl: 'child-panel.component.html',
  styleUrls: ['child-panel.component.scss'],
})
export class ChildPanelComponent implements OnInit {
  membershipOption: MembershipOption[];
  loyaltyPointsCard$: Observable<number | undefined>;

  constructor(
    private authService: AuthService,
    private membershipOptionQuery: MembershipOptionQuery,
    private balanceQuery: BalanceQuery,
    private modalService: NgbModal
  ) {}

  @Input() child: ChildViewModel;

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.membershipOptionQuery.selectAll().subscribe((options) => {
      this.membershipOption = options as MembershipOption[];
    });
  }

  loginToChild() {
    this.authService.switchUser(this.child.MemberId).toPromise();
  }

  openChildBarcode() {
    const modalRef = this.modalService.open(ChildBarcodesCarouselComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'children-barcodes',
    });
    modalRef.componentInstance.childId = this.child.MemberId.toString();
  }
}
