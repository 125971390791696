import { Pipe, PipeTransform } from '@angular/core';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Pipe({ name: 'background' })
export class BackgroundPipe implements PipeTransform {
  productCode = ProductCode;
  className = '';
  /**
   * @returns a class name compatible with each product code
   * @param value Product Code
   * */
  transform(value: string) {
    if (this.isValueIncludeProductCodes(value, this.productCode.Black)) return (this.className = 'black');
    if (this.isValueIncludeProductCodes(value, this.productCode.Diamond)) return (this.className = 'diamond text-black');
    if (this.isValueIncludeProductCodes(value, this.productCode.Rookie)) return (this.className = 'rookie text-black');
    if (this.isValueIncludeProductCodes(value, this.productCode.Pro)) return (this.className = 'pro text-black');
    if (this.isValueIncludeProductCodes(value, this.productCode.Veteran)) return (this.className = 'veteran text-black');
    if (this.isValueIncludeProductCodes(value, this.productCode.Gold_Glove)) return (this.className = 'gold text-black');
    if (this.isValueIncludeProductCodes(value, this.productCode.Trial_Member)) return (this.className = 'trial text-black');
    else return (this.className = 'orange text-black');
  }

  isValueIncludeProductCodes(value: string, productCodes: string[]) {
    return productCodes.includes(value);
  }
}
