import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyType } from '@fgb/core';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';

@Component({
  selector: 'fgb-survey-item-poll',
  templateUrl: './survey-item-poll.component.html',
  styleUrls: ['./survey-item-poll.component.scss', '../../survey.scss'],
})
export class SurveyItemPollComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  surveyType = SurveyType;
  pointsWon: number;
  googleIcons = IconSetting.googleIcon;

  constructor() {}

  ngOnInit() {
    let selectedAnswers = this.survey.SurveyAnswers.filter((sa) => sa.UserSelected);
    if (selectedAnswers && selectedAnswers.length > 0) {
      this.pointsWon = selectedAnswers[0].PointValue / 100;
    }
  }
}
