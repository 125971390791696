import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyType } from '@fgb/core';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';

@Component({
  selector: 'fgb-survey-item-quiz',
  templateUrl: './survey-item-quiz.component.html',
  styleUrls: ['./survey-item-quiz.component.scss', '../../survey.scss'],
})
export class SurveyItemQuizComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  surveyType = SurveyType;
  googleIcons = IconSetting.googleIcon;

  constructor() {}

  ngOnInit() {}
}
