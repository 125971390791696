<nav class="navbar bg-primary navbar-dark navbar-expand-lg d-none d-lg-flex">
  <a class="navbar-brand py-0" [routerLink]="['/']" routerLinkActive="router-link-active">
    <img class="ml-1 h-100" src="assets/images/logo.svg" draggable="false" />
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav mr-auto" #greedyNav>
        <ng-container *notInProductCode="productcode.Trial_Member">
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/rewards/events']" routerLinkActive="router-link-active">{{
              'nav.rewards.title' | translate
            }}</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link mx-1" [routerLink]="['/badges']" routerLinkActive="router-link-active">{{
              'nav.challenges.title' | translate
            }}</a>
          </li>
          <ng-container *inProductCode="productcode.NoPartners">
            <li class="nav-item active">
              <a class="nav-link mx-1" [routerLink]="['/earn']" routerLinkActive="router-link-active">{{
                'nav.earn.title' | translate
              }}</a>
            </li>
            <ng-container *notInProductCode="productcode.ExcludedPartners">
              <li class="nav-item active">
                <a class="nav-link mx-1" [routerLink]="['/partners']" routerLinkActive="router-link-active">{{
                  'account.nav.partners' | translate
                }}</a>
              </li></ng-container
            >
          </ng-container>

          <li [ngClass]="{ hide: hiddenLinks === 0 }" class="nav-item active more-dropdown">
            <div ngbDropdown #ddMore="ngbDropdown" [autoClose]="'outside'" role="group"></div>
          </li>
        </ng-container>

        <li class="nav-item active more-dropdown">
          <div ngbDropdown role="group">
            <a class="nav-link mx-1" ngbDropdownToggle focusTabBtn>
              <span>{{ 'nav.help' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="py-2 mt-2 mr-4 shadow w-100 border-0 rounded help-dropdown" #greedyDropdown>
              <div class="dropdown-item">
                <a [routerLink]="['/faq']" routerLinkActive="router-link-active" class="pl-3">
                  {{ 'nav.faq' | translate }}
                </a>
              </div>
              <div class="dropdown-item">
                <a [routerLink]="['/contact']" routerLinkActive="router-link-active" class="pl-3">
                  {{ 'nav.contact.us' | translate }}
                </a>
              </div>
              <div class="dropdown-item">
                <a [routerLink]="['/terms']" routerLinkActive="router-link-active" class="pl-3">
                  {{ 'nav.terms' | translate }}
                </a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="form-inline my-2 my-lg-0">
      <ng-container *notInProductCode="productcode.Trial_Member">
        <div class="balance font-size-14 text-white mr-3">
          <div class="d-flex align-items-center justify-content-end" *ngIf="loyaltyPointsCard$">
            <span class="icon material-icons currency-icons mr-2">emoji_events</span>
            <span class="font-weight-bold">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe: 'PTS' }}</span>
          </div>
        </div>
        <!--Notifications-->
        <fgb-notification-button></fgb-notification-button>
      </ng-container>

      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'">
        <button
          class="nav-link btn text-white d-flex align-items-center mx-2 px-0 justify-content-end ml-3"
          id="namedropdown"
          ngbDropdownToggle
          aria-label="Account Menu Dropdown"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-left">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="shadow text-center dropdown-menu p-0 account-dropdown mt-2"
        >
          <ng-container *notInProductCode="productcode.Trial_Member">
            <div class="text-info">
              <a
                [routerLink]="['/account']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height rounded-top"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon mr-2">person</span>

                <div class="dropdown-link text-left">
                  <div class="mt-2 font-size-14 font-weight-bold">Account</div>
                  <div class="nav-bar-little-text text-left font-size-10" *ngIf="card$ | async as memberCard">
                    {{ memberCard.ProductCode }}
                  </div>
                </div>
              </a>
            </div>
          </ng-container>

          <ng-container *inProductCode="productcode.Trial_Member">
            <div class="text-info">
              <a
                class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height rounded-top"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon mr-2">person</span>

                <div class="dropdown-link text-left">
                  <div class="mt-2 font-size-14 font-weight-bold">Account</div>
                  <div class="nav-bar-little-text text-left font-size-10" *ngIf="card$ | async as memberCard">
                    {{ memberCard.ProductCode }}
                  </div>
                </div>
              </a>
            </div>
          </ng-container>

          <hr class="text-muted w-75 my-0" />

          <div class="d-flex align-items-center py-0 px-2 nav-item-fixed-height" ngbDropdownItem>
            <img class="account-logo mr-2" src="assets/images/logo.svg" draggable="false" />
            <div class="dropdown-link mt-1 text-left">
              <div class="text-primary font-weight-bold font-size-14 mb-1">{{ 'nav.account.manager' | translate }}</div>
            </div>
          </div>

          <hr class="text-muted w-75 my-0" />
          <ng-container *notInProductCode="productcode.Trial_Member">
            <div class="text-black">
              <a
                [routerLink]="['/rewards/wallet']"
                routerLinkActive="router-link-active"
                class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
                ngbDropdownItem
              >
                <span class="icon material-icons material-dropdown-icon mr-2">account_balance_wallet</span>

                <div class="dropdown-link mt-1 text-left">
                  <div class="font-weight-bold font-size-14 mb-1">{{ 'nav.wallet' | translate }}</div>
                </div>
              </a>
            </div>

            <hr class="text-muted w-75 my-0" />
          </ng-container>
          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              aria-label="Log out"
            >
              <span class="icon material-icons material-dropdown-icon mr-2 text-danger">power_settings_new</span>

              <div class="dropdown-link text-left">
                <div class="py-1 font-weight-bold font-size-14 text-danger" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <!-- <fgbcl-loading [size]="'small'"></fgbcl-loading> -->
  </div>
</ng-template>
