<div class="card">
  <div class="mt-5" *ngIf="loadingPaymentForm">
    <fgbcl-loading></fgbcl-loading>
  </div>
  <div class="card-body" *ngIf="portalProducts">
    <div class="row">
      <div class="col-lg-12">
        <!--Membership Options-->
        <ng-container *ngIf="currentStep == 0">
          <div class="row h-100">
            <div class="col-lg-5 d-none d-lg-block login-banner"></div>
            <div class="col-lg-7 personal-details-panel h-100">
              <div class="px-3 h-100 terms-panel pb-5">
                <div class="d-flex justify-content-center align-items-center mb-4 d-lg-none">
                  <img src="assets/images/KN-logo.png" class="kid-logo" />
                </div>
                <h2 class="text-lg-left text-center heading-title text-primary mb-4">Membership Options</h2>
                <p class="heading-text">Please choose a membership option for each child in the list below:</p>
                <div class="membership-child-list">
                  <div class="mb-3" *ngFor="let child of childUpgrades">
                    <div class="py-2 m-1">
                      <div class="row">
                        <div class="col-lg-6">
                          <label class="mb-0 pt-1">{{ child.FirstName + ' ' + child.LastName }}</label>
                          <p class="mb-0">{{ child.DateOfBirth | date }}</p>
                        </div>
                        <div class="col-lg-5 offset-0 offset-lg-1">
                          <div class="form-group mb-0">
                            <select
                              class="form-control bg-muted mt-2 p-1"
                              [value]="child.Product.ProductId"
                              (change)="childProductChange(child, $event.target.value)"
                            >
                              <option value="0">Do Not Upgrade</option>
                              <ng-container *ngFor="let product of portalProducts">
                                <option [value]="product.PortalProduct.ProductId">
                                  {{ product.PortalProduct.DisplayName }}
                                </option>
                              </ng-container>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="membership-option-details mb-3">
                  <div class="card border-0 shadow mb-2 pr-2" *ngFor="let product of portalProducts">
                    <div class="card-body py-1">
                      <div class="row align-items-center">
                        <div class="col-2">
                          <!-- <img class="option-image" [src]="product.PrimaryImage" /> -->
                        </div>
                        <p class="col-9 m-0 text-primary">{{ product.PortalProduct.DisplayName }}</p>
                        <div class="col-1">
                          <button
                            class="btn btn-secondary btn-circle p-0 position-relative option-toggle"
                            (click)="product.ShowDescription = !product.ShowDescription"
                            aria-label="Open/Close Description"
                          >
                            <span *ngIf="!product.ShowDescription" class="icon icon-arrow-1-down"></span
                            ><span *ngIf="product.ShowDescription" class="icon icon-arrow-1-up"></span>
                          </button>
                        </div>
                      </div>
                      <div class="option-description p-1" *ngIf="product.ShowDescription">
                        {{ product.PortalProduct.Description }}
                      </div>
                    </div>
                  </div>

                  <div class="d-inline-flex mt-3">
                    <div class="upgrade-image">
                      <img src="assets/images/What Glove Do You Need.jpg" />
                    </div>

                    <div>
                      <div class="pl-2 upgrade-paragraph">
                        Please note that a “right-handed” kit is for a child that throws right-handed, and a “left-handed” kit is
                        for a child that throws left-handed. Free shipping on all domestic orders. Before upgrading your child’s
                        membership please contact
                        <span class="text-primary upgrade-email">kidnation@redsox.com</span> to update your current shipping
                        address.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="button-container px-3 pb-3 pb-lg-0 d-flex justify-content-between">
                <!-- Previous Button -->
                <button (click)="CloseModel()" class="btn btn-primary nav-button font-weight-bold mb-0">
                  Back
                  <ng-template #noPreviousStep>Back</ng-template>
                </button>

                <!-- Next Button -->
                <button (click)="showNext(1)" class="btn btn-secondary nav-button font-weight-bold mb-0">Next</button>
              </div>
            </div>
          </div>
        </ng-container>

        <!--Review Payment-->
        <ng-container *ngIf="currentStep == 1">
          <div class="p-1 review-panel py-1 px-3 px-lg-3">
            <div class="d-flex justify-content-center align-items-center mb-4 mt-3 d-lg-none">
              <img src="assets/images/KN-logo.png" class="kid-logo" />
            </div>
            <h5 class="text-lg-left text-center text-secondary mt-3 mb-4">Review Purchases</h5>
            <div class="font-weight-bold font-size-14">Payment Summary</div>

            <div class="row">
              <div class="col-lg-7">
                <hr class="border-info my-2 w-100" />
                <div class="font-size-12">
                  <div class="row font-weight-bold">
                    <div class="col-9 col-lg-10">Membership Option</div>
                    <div class="col-3 col-lg-2">Cost</div>
                  </div>

                  <div class="text-black my-2">
                    <div *ngFor="let child of childUpgrades">
                      <div class="row my-1" *ngIf="child.Product.ProductId > 0">
                        <div class="col-9 col-lg-10">
                          <span class="font-weight-bold">{{ child.FirstName }} {{ child.LastName }}</span>
                          -
                          {{ child.Product.DisplayName }}
                        </div>
                        <div class="col-3 col-lg-2">
                          {{ child.Product.MerchantPrice / 100 | FortressCurrencyPipe }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="border-info my-2 w-100" />
              </div>

              <!-- Right Side -->
              <div [ngClass]="{ 'd-none': editingBillingAddress || editingShippingAddress }" class="col-lg-5">
                <div class="position-relative rounded bg-muted p-2 font-size-14">
                  <div class="top-right text-primary font-weight-bold cursor-pointer">
                    <a (click)="editBillingAddress()">Change</a>
                  </div>
                  <h6 class="mb-1">Billing Address</h6>
                  <div class="text-info">
                    <p class="mb-0 add-info">{{ billingAddress.Street }}</p>
                    <p class="mb-0 add-info">{{ billingAddress.Town }}</p>
                    <p class="mb-0 add-info">{{ billingAddress.County }}</p>
                    <p class="mb-0 add-info">{{ billingAddress.Postcode }}</p>
                    <p class="mb-0 add-info">{{ billingAddress.Country }}</p>
                    <p class="mb-0 add-info">{{ billingAddress.HomeNumber }}</p>
                  </div>
                </div>
                <div class="position-relative rounded bg-muted p-2 my-2 font-size-14">
                  <div class="top-right text-primary font-weight-bold cursor-pointer">
                    <a (click)="editShippingAddress()">Change</a>
                  </div>
                  <h6 class="mb-1">Shipping Address</h6>
                  <div class="text-info">
                    <p class="mb-0 add-info">{{ shippingAddress.Street }}</p>
                    <p class="mb-0 add-info">{{ shippingAddress.Town }}</p>
                    <p class="mb-0 add-info">{{ shippingAddress.County }}</p>
                    <p class="mb-0 add-info">{{ shippingAddress.Postcode }}</p>
                    <p class="mb-0 add-info">{{ shippingAddress.Country }}</p>
                    <p class="mb-0 add-info">{{ shippingAddress.HomeNumber }}</p>
                  </div>
                </div>

                <fgbcl-errors></fgbcl-errors>

                <div class="input-group rounded my-2">
                  <input type="text" class="form-control border-0 bg-muted" [(ngModel)]="giftCodeInput" />
                  <div class="input-group-append">
                    <button class="btn btn-info text-white form-control heading-text-one" type="button" (click)="applyGiftCode()">
                      Apply Gift Code
                    </button>
                  </div>
                </div>

                <div class="rounded bg-muted p-2 my-2">
                  <h6 class="mb-4">Summary</h6>
                  <div class="row text-info font-size-12">
                    <div class="col-9">Items:</div>
                    <div class="col-3 text-right">{{ originalAmount / 100 | FortressCurrencyPipe }}</div>
                  </div>
                  <div class="row text-info font-size-12">
                    <div class="col-9">Shipping:</div>
                    <div class="col-3 text-right">{{ totalShipping / 100 | FortressCurrencyPipe }}</div>
                  </div>
                  <div class="row text-info font-size-12" *ngIf="appliedGiftCodes && appliedGiftCodes.length > 0">
                    <div class="col-12">Discount Code(s):</div>
                    <ng-container *ngFor="let giftCode of appliedGiftCodes">
                      <div class="col-9"><button (click)="removeGiftCode(giftCode.Code)">X</button> {{ giftCode.Code }}</div>
                      <div class="col-3 text-right">
                        <span *ngIf="giftCode.EntitlementValueType == 0"
                          >- {{ giftCode.EntitlementValue / 100 | FortressCurrencyPipe }}</span
                        >
                        <span *ngIf="giftCode.EntitlementValueType == 1">- {{ giftCode.EntitlementValue }}%</span>
                        <span *ngIf="giftCode.EntitlementValueType == 2">FREE</span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="row font-weight-bold font-size-14 mt-2">
                    <div class="col-9">Total (Incl. Shipping):</div>
                    <div class="col-3 text-right">
                      {{ (totalShipping + totalAmount + totalTax) / 100 | FortressCurrencyPipe }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- EDIT BILLING ADDRESS FORM -->
              <ng-container *ngIf="editingBillingAddress">
                <div class="col-lg-5">
                  <div class="position-relative rounded bg-muted p-2">
                    <div class="top-right text-primary font-weight-bold cursor-pointer font-size-14">
                      <a (click)="clearAddressForm(billingAddressForm)">Clear Form</a>
                    </div>
                    <h6 class="mb-2 font-size-14">Billing Address</h6>
                    <form [formGroup]="billingAddressForm">
                      <div class="form-row">
                        <div class="form-group col-6">
                          <label for="FirstName">
                            First Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="FirstName"
                            class="form-control"
                            type="text"
                            formControlName="FirstName"
                            [ngClass]="{
                              'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.FirstName.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your first name.</div>
                        </div>

                        <div class="form-group col-6">
                          <label for="Surname">
                            Surname
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="Surname"
                            class="form-control"
                            type="text"
                            formControlName="Surname"
                            [ngClass]="{
                              'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Surname.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your surname.</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="Street">
                          Street
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Street"
                          class="form-control"
                          name="Street"
                          type="text"
                          formControlName="Street"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Street.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="Town">
                          City
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Town"
                          class="form-control"
                          name="Town"
                          type="text"
                          formControlName="Town"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Town.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="County">
                          State
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="County"
                          class="form-control"
                          name="County"
                          type="text"
                          formControlName="County"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.County.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="Postcode">
                          Zip Code
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Postcode"
                          class="form-control"
                          name="Postcode"
                          type="text"
                          formControlName="Postcode"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Postcode.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid zip code.</div>
                      </div>

                      <div class="form-group">
                        <label for="Country">
                          Country
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Country"
                          class="form-control"
                          name="Country"
                          type="text"
                          formControlName="Country"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.Country.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="Country">
                          Home Number
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="HomeNumber"
                          class="form-control"
                          name="HomeNumber"
                          type="text"
                          formControlName="HomeNumber"
                          [ngClass]="{
                            'is-invalid': hasSubmittedBillingAddress && !!billingAddressForm.controls.HomeNumber.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>
                    </form>
                    <div class="d-flex justify-content-between my-2">
                      <button class="btn btn-danger" (click)="editingBillingAddress = false">Cancel</button>
                      <button class="btn btn-primary" (click)="updateBillingAddress()">Save</button>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="editingShippingAddress">
                <div class="col-lg-5">
                  <div class="position-relative rounded bg-muted p-2">
                    <div class="top-right text-primary font-weight-bold cursor-pointer font-size-14">
                      <a (click)="clearAddressForm(shippingAddressForm)">Clear Form</a>
                    </div>
                    <h6 class="mb-2 font-size-14">Shipping Address</h6>
                    <form [formGroup]="shippingAddressForm">
                      <div class="form-row">
                        <div class="form-group col-6">
                          <label for="FirstName">
                            First Name
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="FirstName"
                            class="form-control"
                            type="text"
                            formControlName="FirstName"
                            [ngClass]="{
                              'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.FirstName.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your first name.</div>
                        </div>

                        <div class="form-group col-6">
                          <label for="Surname">
                            Surname
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            id="Surname"
                            class="form-control"
                            type="text"
                            formControlName="Surname"
                            [ngClass]="{
                              'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Surname.errors
                            }"
                            required
                          />
                          <div class="invalid-feedback">Please enter your surname.</div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="Street">
                          Street
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Street"
                          class="form-control"
                          name="Street"
                          type="text"
                          formControlName="Street"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Street.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="Town">
                          City
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Town"
                          class="form-control"
                          name="Town"
                          type="text"
                          formControlName="Town"
                          [ngClass]="{ 'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Town.errors }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="County">
                          State
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="County"
                          class="form-control"
                          name="County"
                          type="text"
                          formControlName="County"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.County.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>

                      <div class="form-group">
                        <label for="Postcode">
                          Zip Code
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Postcode"
                          class="form-control"
                          name="Postcode"
                          type="text"
                          formControlName="Postcode"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Postcode.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a valid zip code.</div>
                      </div>

                      <div class="form-group">
                        <label for="Country">
                          Country
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="Country"
                          class="form-control"
                          name="Country"
                          type="text"
                          formControlName="Country"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.Country.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>
                      <div class="form-group">
                        <label for="Country">
                          Home Number
                          <span class="text-danger">*</span>
                        </label>
                        <input
                          id="HomeNumber"
                          class="form-control"
                          name="HomeNumber"
                          type="text"
                          formControlName="HomeNumber"
                          [ngClass]="{
                            'is-invalid': hasSubmittedShippingAddress && !!shippingAddressForm.controls.HomeNumber.errors
                          }"
                          required
                        />
                        <div class="invalid-feedback">Please enter a value.</div>
                      </div>
                    </form>
                    <div class="d-flex justify-content-between my-2">
                      <button class="btn btn-danger" (click)="editingShippingAddress = false">Cancel</button>
                      <button class="btn btn-primary" (click)="updateShippingAddress()">Save</button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="button-container px-3 mb-3 mb-lg-0 d-flex justify-content-between">
            <!-- Previous Button -->
            <button (click)="showPrevious(0)" class="btn btn-primary nav-button font-weight-bold mb-0">
              Back
              <ng-template #noPreviousStep>Back</ng-template>
            </button>

            <!-- Next Button -->
            <button (click)="showNext(2)" class="btn btn-secondary nav-button font-weight-bold mb-0">Confirm</button>
          </div>
        </ng-container>

        <!--Payment Process-->

        <ng-container *ngIf="currentStep == 2">
          <div class="col-12 col-lg-6 offset-lg-3">
            <h2 class="text-center text-secondary ml-0 mt-4 mb-4">Add Payment Details</h2>
            <!-- Summary -->
            <div class="p-2 mt-2 mb-5">
              <div class="row font-weight-bold text-black mt-2">
                <div class="col-9">Total:</div>
                <div class="col-3">{{ (totalAmount + totalShipping + totalTax) / 100 | FortressCurrencyPipe }}</div>
              </div>
            </div>
            <!-- Payment Provider Form -->
            <div id="paymentDiv"></div>
            <button class="btn btn-primary w-100 mb-3 mt-5 font-size-14 font-weight-bold" (click)="showPrevious(1)">
              Cancel
            </button>
          </div>
        </ng-container>

        <ng-container *ngIf="orderCompleted">
          <div class="col-12 col-lg-6 offset-lg-3">
            <h2 class="text-center text-secondary ml-0 mt-4 mb-4">Success</h2>
            Child membership upgraded.
            <button class="btn btn-primary w-100 mb-3 mt-5 font-size-14 font-weight-bold" (click)="CloseModel()">close</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
