<!-- Banner -->
<div class="d-none d-lg-block">
  <fgb-banner [title]="'lotto.page.title' | translate"></fgb-banner>

  <!-- Nav Bar -->
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>
<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/raffles'" [backButtonTitle]="'Raffles'"></fgb-points-summary-bar>
</div>

<!-- Auction Details -->
<fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>

<!-- Back Button -->
<div class="container">
  <div class="text-primary pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards/raffles'">
    <span class="material-icons"> keyboard_arrow_left </span>
    <span class="font-size-14 font-weight-bold">{{ 'rewards.back.to.lottos' | translate }}</span>
  </div>
</div>
