<div class="d-lg-block d-none">
  <fgb-banner [title]="'lotto.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-lottos-list [isList]="true"></fgbcl-lottos-list>
</div>
