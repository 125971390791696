import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss']
})
export class RewardsPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
