import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ContentImagePipe } from './pipes/content-image.pipe';
import { FortressCurrencyPipe } from './pipes/fortress-currency.pipe';
import { ManageTicketsComponent } from './components/manage-tickets/manage-tickets.component';
import { CreditCardNumberPipe } from './pipes/credit-card-number.pipe';
import { CardTypeImageUrlPipe } from './pipes/card-type-image-url.pipe';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CountdownComponent } from './components/countdown/countdown.component';
import { ImgDefaultDirective } from './directives/img-default.directive';
import { LoyaltyIconComponent } from './components/loyalty-icon/loyalty-icon.component';
import { InPopulationDirective } from './directives/in-population.directive';
import { NotInPopulationDirective } from './directives/not-in-population.directive';
import { IsClientTypeDirective } from './directives/is-client-type.directive';
import { IsNotClientTypeDirective } from './directives/is-not-client-type.directive';
import { StickyDirective } from './directives/sticky.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalDirective } from './directives/confirm-modal.directive';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { GiftCodesComponent } from './components/gift-codes/gift-codes.component';
import { InProductCodeDirective } from './directives/in-product-code.directive';
import { NotInProductCodeDirective } from './directives/not-in-product-code.directive';
import { TmCallbackComponent } from './components/manage-tickets/tm-callback/tm-callback.component';
import { ConfirmModalTermsDirective } from './directives/confirm-modal-terms.directive';
import { ConfirmModalTermsComponent } from './components/confirm-modal-terms/confirm-modal-terms.component';
import { RouterModule } from '@angular/router';
import { HasTagDirective } from './directives/has-tag.directive';
import { NotHasTagDirective } from './directives/not-has-tag.directive';
import { TmAuthCallbackComponent } from './components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { ContentFilePipe } from './pipes/content-file.pipe';
import { FgbYoutubePlayerComponent } from './components/providers/youtube/fgb-youtube-player/fgb-youtube-player.component';
import { FgbVimeoPlayerComponent } from './components/providers/vimeo/fgb-vimeo-player/fgb-vimeo-player.component';
import { FgbSVPPlayerComponent } from './components/providers/streamingVideoProvider/fgb-svp-player/fgb-svp-player.component';
import { FgbTwitchPlayerComponent } from './components/providers/twitch/fgb-twitch-player/fgb-twitch-player.component';
import { FgbZoomConferenceComponent } from './components/providers/zoom/fgb-zoom-conference/fgb-zoom-conference.component';
import { FgbBoxcastPlayerComponent } from './components/providers/boxcast/fgb-boxcast-player/fgb-boxcast-player.component';
import { FgbStreamablePlayerComponent } from './components/providers/streamable/fgb-streamable-player/fgb-streamable-player.component';
import { FgbFacebookPlayerComponent } from './components/providers/facebook/fgb-facebook-player/fgb-facebook-player.component';
import { TimeSinceComponent } from './components/time-since/time-since.component';
import { UtcToLocaltimePipe } from './pipes/utc-to-local.pipe';
import { CartItemComponent } from '../modules/loyalty/components/cart-item/cart-item.component';
import { CartMobileShortcutComponent } from '../modules/loyalty/components/cart-item/cart-mobile-shortcut/cart-mobile-shortcut.component';
import { CartModalComponent } from '../modules/loyalty/components/cart-item/cart-modal/cart-modal.component';
import { CustomPopupComponent } from './components/custom-popup/custom-popup.component';
import { PopupInfoComponent } from './components/custom-popup/popup-info/popup-info.component';
import { PopupActionConfirmComponent } from './components/custom-popup/popup-action-confirm/popup-action-confirm.component';
import { PopupActionOkCancelComponent } from './components/custom-popup/popup-action-ok-cancel/popup-action-ok-cancel.component';
import { CtrlTemplateDirective } from './directives/control-template.directive';

import { CountdownWatchComponent } from './components/countdown-watch/countdown-watch.component';
import { SortBarComponent } from './components/sort-bar/sort-bar.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgSubscribeDirective } from './directives/ng-subscribe.directive';
import { SportsAllianceCallbackComponent } from './components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { FGBMissingTranslationHandler, FGBTranslationLoader } from '@fgb/core';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';
import { PipesModule } from '@fgb/portal-component-library/src/lib/pipes';
import { LoadingModule } from '@fgb/portal-component-library/src/lib/shared/loading';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { PortalIdModule } from '@fgb/portal-component-library/src/lib/shared/portal-id';
import { CountryAndRegionsModule } from '@fgb/portal-component-library/src/lib/shared/country-and-regions';
import { NumberInputModule } from '@fgb/portal-component-library/src/lib/shared/number-input';
import { BannerComponent } from './banner/banner.component';
import { BackgroundPipe } from './pipes/background.pipe';
import { LogoPipe } from './pipes/logo.pipe';

@NgModule({
  declarations: [
    ContentImagePipe,
    ContentFilePipe,
    FortressCurrencyPipe,
    CreditCardNumberPipe,
    CardTypeImageUrlPipe,
    SafeHtmlPipe,
    WithLoadingPipe,
    BackgroundPipe,
    LogoPipe,
    ManageTicketsComponent,
    CountdownComponent,
    ImgDefaultDirective,
    LoyaltyIconComponent,
    InPopulationDirective,
    NotInPopulationDirective,
    IsNotClientTypeDirective,
    IsClientTypeDirective,
    StickyDirective,
    ConfirmModalDirective,
    ConfirmModalComponent,
    GiftCodesComponent,
    InProductCodeDirective,
    NotInProductCodeDirective,
    TmCallbackComponent,
    ConfirmModalTermsDirective,
    ConfirmModalTermsComponent,
    HasTagDirective,
    NotHasTagDirective,
    TmAuthCallbackComponent,
    FgbYoutubePlayerComponent,
    FgbVimeoPlayerComponent,
    FgbSVPPlayerComponent,
    FgbTwitchPlayerComponent,
    FgbZoomConferenceComponent,
    FgbBoxcastPlayerComponent,
    FgbStreamablePlayerComponent,
    TimeSinceComponent,
    UtcToLocaltimePipe,
    FgbFacebookPlayerComponent,
    CartItemComponent,
    CartModalComponent,
    CartMobileShortcutComponent,
    CustomPopupComponent,
    PopupInfoComponent,
    PopupActionConfirmComponent,
    PopupActionOkCancelComponent,
    CtrlTemplateDirective,
    CountdownWatchComponent,
    SortBarComponent,
    NgSubscribeDirective,
    SportsAllianceCallbackComponent,
    TermsAndConditionsComponent,
    BannerComponent,
  ],
  entryComponents: [ConfirmModalComponent, ConfirmModalTermsComponent, CartModalComponent, CustomPopupComponent],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    NgbModule,
    LoadingModule,
    RouterModule,
    InlineSVGModule,
    CountryAndRegionsModule,
    GoogleMapsModule,
    ErrorsModule,
    NumberInputModule,
    PortalIdModule,
    PipesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
  ],
  exports: [
    ContentImagePipe,
    ContentFilePipe,
    FortressCurrencyPipe,
    CountryAndRegionsModule,
    LoadingModule,
    CreditCardNumberPipe,
    CardTypeImageUrlPipe,
    SafeHtmlPipe,
    WithLoadingPipe,
    BackgroundPipe,
    LogoPipe,
    ManageTicketsComponent,
    CountdownComponent,
    ImgDefaultDirective,
    LoyaltyIconComponent,
    InPopulationDirective,
    NotInPopulationDirective,
    IsNotClientTypeDirective,
    IsClientTypeDirective,
    ConfirmModalDirective,
    StickyDirective,
    GiftCodesComponent,
    InProductCodeDirective,
    NotInProductCodeDirective,
    ConfirmModalTermsDirective,
    HasTagDirective,
    NotHasTagDirective,
    TimeSinceComponent,
    FgbYoutubePlayerComponent,
    FgbVimeoPlayerComponent,
    FgbSVPPlayerComponent,
    FgbTwitchPlayerComponent,
    FgbZoomConferenceComponent,
    FgbBoxcastPlayerComponent,
    FgbStreamablePlayerComponent,
    UtcToLocaltimePipe,
    FgbFacebookPlayerComponent,
    CartItemComponent,
    CartModalComponent,
    CartMobileShortcutComponent,
    CustomPopupComponent,
    CtrlTemplateDirective,
    CountdownWatchComponent,
    SortBarComponent,
    NgSubscribeDirective,
    TermsAndConditionsComponent,
    GoogleMapsModule,
    PipesModule,
    NumberInputModule,
    PortalIdModule,
    BannerComponent
  ],
})
export class SharedModule {}
