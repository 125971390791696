// External Dependencies
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { JwtModule } from '@auth0/angular-jwt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { QRCodeModule } from 'angularx-qrcode';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { InlineSVGModule } from 'ng-inline-svg';
// Routing & Configuration
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
// Feature Modules
import { SharedModule } from './shared/shared.module';
import { ClubModule } from './modules/club/club.module';
import { UserModule } from './modules/user/user.module';
import { LoyaltyModule } from './modules/loyalty/loyalty.module';
import { PurchaseModule } from './modules/purchase/purchase.module';
import { GuardianModule } from './modules/guardian/guardian.module';
import { EventsModule } from './modules/events/events.module';
import { PassesModule } from './modules/passes/passes.module';
import { DisplayModule } from './modules/display/display.module';

// Utilities
import { MomentDateFormatter } from './shared/utilities/moment-date-formatter';
// @fgb/core
import {
  AuthGuard,
  PopulationGuard,
  ProductCodeGuard,
  jwtTokenGetter,
  AuthService,
  LoggingService,
  ErrorStateService,
  TokenRefreshIntereptor,
  HttpErrorInterceptor,
  MemberQuery,
  RegistrationStepService,
  FirstTimeRegistrationInterceptor,
  TMOauthService,
  TMAuthService,
  FGBTranslationLoader,
  FGBMissingTranslationHandler,
  MaintenanceGuard,
  DATE_OPTIONS,
  NotificationLoyaltyType,
  REGISTRATION_ROUTES,
} from '@fgb/core';

//Component Library
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { PortalLinkCardModule } from '@fgb/portal-component-library/src/lib/shared/portal-link-card';
import { PortalIdModule } from '@fgb/portal-component-library/src/lib/shared/portal-id';
import { EarnPointsModule } from '@fgb/portal-component-library/src/lib/club/earn-points';
import { FaqSectionModule } from '@fgb/portal-component-library/src/lib/club/faq-section';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
// General Pages
import { AppComponent } from './app.component';
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { ReferAFriendPageComponent } from './pages/refer-a-friend-page/refer-a-friend-page.component';
import { ReferAFriendSuccessPageComponent } from './pages/refer-a-friend-page/refer-a-friend-success-page/refer-a-friend-success-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { CollectAddressModal } from './modules/loyalty/components/collect-address-modal/collect-address-modal.component';
// Loyalty Pages
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { LottosPageComponent } from './pages/lottos-page/lottos-page.component';
import { MarketplacePageComponent } from './pages/marketplace-page/marketplace-page.component';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { AuctionBidsPageComponent } from './pages/auction-bids-page/auction-bids-page.component';
import { AuctionBidDetailsPageComponent } from './pages/auction-bid-details-page/auction-bid-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { RewardsShippingPageComponent } from './pages/rewards-shipping-page/rewards-shipping-page.component';
import { WalletRedeemedListModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-redeemed-list';
import { WalletRedeemListModule } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-redeem-list';

// Polls, Surveys & Trivia (Games)
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { PredictorPageComponent } from './pages/predictor-page/predictor-page.component';
// Account Pages
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';

// Tickets Pages
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { MobilePassModalComponent } from './modules/passes/components/mobile-pass-modal/mobile-pass-modal.component';
// Portal Products
import { PortalProductResultsPageComponent } from './pages/portal-products-pages/portal-product-results-page/portal-product-results-page.component';
import { PortalProductSuccessPageComponent } from './pages/portal-products-pages/portal-product-success-page/portal-product-success-page.component';
import { PortalProductFailurePageComponent } from './pages/portal-products-pages/portal-product-failure-page/portal-product-failure-page.component';
import { UpgradeChildrenComponent } from './modules/guardian/components/upgrade-children/upgrade-children.component';
// External Cash Transfer
import { CardPageComponent } from './pages/card-page/card-page.component';
// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { GuardianPageComponent } from './pages/guardian-page/guardian-page.component';
import { LayoutModule } from './modules/layouts/layout.module';

// Points Management Page
import { PointsManagementPageComponent } from './pages/points-management-page/points-management-page.component';

// Partners Pages
import { PartnerPageComponent } from './pages/partner-page/partner-page.component';
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { JokesPageComponent } from './pages/jokes-page/jokes-page.component';
import { ZoomMeetingPageComponent } from './pages/zoom-meeting-page/zoom-meeting-page.component';
import { CartCheckoutPagePortalProductsComponent } from './pages/cart-checkout-page-portal-products/cart-checkout-page-portal-products.component';
import { TailgatePageComponent } from './pages/tailgate-page/tailgate-page.component';
import { ProductsPageComponent } from './pages/products-page/products-page.component';
import { VouchersModule } from './modules/vouchers/vouchers.module';
import { ScorecardCarouselModule } from '@fgb/portal-component-library/src/lib/loyalty/scorecards/scorecard-carousel';
import { FeaturedRewardsModule } from '@fgb/portal-component-library/src/lib/loyalty/featured-rewards';
import { MarketplaceListModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list';
import { MixedLoyaltyListModule } from '@fgb/portal-component-library/src/lib/loyalty/mixed-loyalty/mixed-loyalty-list';
import { AuctionListModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-list';
import { AuctionDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/auctions/auction-details';
import { BadgeFeaturedListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-featured-list';
import { BarcodeModule } from '@fgb/portal-component-library/src/lib/user/barcode';
import { BadgeOverviewListModule } from '@fgb/portal-component-library/src/lib/loyalty/badges/badge-overview-list';
import { MarketplaceDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-details';
import { TermsAndConditionsModule } from '@fgb/portal-component-library/src/lib/club/terms-and-conditions';
import { PointsTransferSuccessPageComponent } from './pages/points-transfer-success-page/points-transfer-success-page.component';
import { LottosListModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lottos-list';
import { LottoDetailsModule } from '@fgb/portal-component-library/src/lib/loyalty/lottos/lotto-details';
import { DirectivesModule } from '@fgb/portal-component-library/src/lib/directives';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import {
  ALERT_NOTIFICATION_COMPONENTS,
  AlertNotificationComponentMap,
  mergeAlertNotificationComponentMaps,
} from '@fgb/portal-component-library/src/lib/club/notification/';
import { V2ScorecardDescriptionModalComponent } from '@fgb/portal-component-library/src/lib/loyalty/scorecards/v2-scorecards/v2-scorecard-description-modal';
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { mergeRegistrationRoutes, registrationRoutes } from '@fgb/portal-component-library/src/lib/registration';
import { AnnouncementsModule } from '@fgb/portal-component-library/src/lib/club/announcements';
import { CampaignsOffersListModule } from '@fgb/portal-component-library/src/lib/loyalty/campaigns-offers/campaigns-offers-list';
import { CampaignDetailsPageComponent } from './pages/campaign-details-page/campaign-details-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
import { WalletV2Module } from '@fgb/portal-component-library/src/lib/loyalty/wallet/wallet-v2';
const alertNotificationComponents: Partial<AlertNotificationComponentMap> = {
  [NotificationLoyaltyType.ScorecardComplete]: V2ScorecardDescriptionModalComponent,
};
@NgModule({
  declarations: [
    // General Pages
    AppComponent,
    MasterPageComponent,
    HomePageComponent,
    LoginPageComponent,
    LoginAccountsPageComponent,
    MaintenancePageComponent,
    TermsPageComponent,
    FaqPageComponent,
    ContactUsPageComponent,
    ReferAFriendPageComponent,
    ReferAFriendSuccessPageComponent,
    NotFoundPageComponent,
    // Loyalty Pages
    RewardsLayoutComponent,
    RewardsPageComponent,
    AuctionsPageComponent,
    LottosPageComponent,
    MarketplacePageComponent,
    EventsPageComponent,
    WalletPageComponent,
    RewardsShippingPageComponent,
    AuctionDetailPageComponent,
    LottoDetailPageComponent,
    MarketplaceDetailPageComponent,
    LottosSuccessPageComponent,
    MarketplacePurchaseSuccessPageComponent,
    AuctionBidsPageComponent,
    AuctionBidDetailsPageComponent,
    LottoWalletDetailsPageComponent,
    // Polls, Surveys & Trivia Pages (Games)
    GamesLayoutComponent,
    PollsPageComponent,
    PredictorPageComponent,
    // Account Pages
    AccountDetailsPageComponent,
    ActivityPageComponent,
    PointsManagementPageComponent,
    PortalProductResultsPageComponent,
    PortalProductSuccessPageComponent,
    PortalProductFailurePageComponent,
    // Tickets Pages

    ContactsPageComponent,
    // Guardians Pages
    GuardianPageComponent,
    // External Cash Transfers
    CardPageComponent,
    // Club Pages
    EarnPageComponent,
    PartnerPageComponent,
    LottosTermsPageComponent,
    MarketplaceTermsPageComponent,
    JokesPageComponent,
    ZoomMeetingPageComponent,
    CartCheckoutPagePortalProductsComponent,
    BadgesPageComponent,
    TailgatePageComponent,
    ProductsPageComponent,
    PointsTransferSuccessPageComponent,
    RegistrationLayoutPageComponent,
    CampaignDetailsPageComponent,
    WalletClaimedPageComponent,
  ],
  imports: [
    // External Modules
    PortalIdModule,
    RouterModule,
    CarouselModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    QRCodeModule,
    BrowserAnimationsModule,
    EarnPointsModule,
    FaqSectionModule,
    PortalLinkCardModule,
    ErrorsModule,
    DirectivesModule,
    AnnouncementsModule,
    WalletV2Module,
    ToastrModule.forRoot({
      maxOpened: 5,
    }),
    JwtModule.forRoot({
      config: {
        allowedDomains: ['http://localhost/portal2/api'],
        tokenGetter: jwtTokenGetter,
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient, AuthService, MemberQuery],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    // Feature Modules
    LayoutModule,
    GuardianModule,
    PurchaseModule,
    EventsModule,
    ClubModule,
    VouchersModule,
    PurchaseModule,
    WalletRedeemedListModule,
    WalletRedeemListModule,
    UserModule,
    SharedModule,
    LoyaltyModule,
    PassesModule,
    DisplayModule,
    ScorecardCarouselModule,
    FeaturedRewardsModule,
    MarketplaceListModule,
    MixedLoyaltyListModule,
    AuctionListModule,
    AuctionDetailsModule,
    BadgeFeaturedListModule,
    BarcodeModule,
    BadgeOverviewListModule,
    MarketplaceDetailsModule,
    TermsAndConditionsModule,
    LottosListModule,
    LottoDetailsModule,
    CampaignsOffersListModule,
    NotificationModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenRefreshIntereptor,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FirstTimeRegistrationInterceptor,
      multi: true,
      deps: [MemberQuery, RegistrationStepService, LoggingService, Router],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [ErrorStateService, LoggingService, TranslateService, Router, AuthService],
    },
    {
      provide: DATE_OPTIONS,
      useValue: {
        LongDateFormat: 'DD MMM, yyyy h:mmA',
        NgbDateFormat: 'MMM DD, yyyy',
      },
    },
    {
      provide: ALERT_NOTIFICATION_COMPONENTS,
      useFactory: () => {
        return mergeAlertNotificationComponentMaps(alertNotificationComponents);
      },
    },
    {
      provide: REGISTRATION_ROUTES,
      useValue: registrationRoutes,
    },
    {
      provide: REGISTRATION_ROUTES,
      useFactory: () => mergeRegistrationRoutes(),
    },
    TMOauthService,
    TMAuthService,
    AuthGuard,
    AuthService,
    PopulationGuard,
    ProductCodeGuard,
    MaintenanceGuard,
    DatePipe,
    CurrencyPipe,
    CookieService,
    { provide: NgbDateParserFormatter, useClass: MomentDateFormatter },
  ],
  bootstrap: [AppComponent],
  entryComponents: [CollectAddressModal, MobilePassModalComponent, UpgradeChildrenComponent],
})
export class AppModule {}
