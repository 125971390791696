import { Directive, TemplateRef, ViewContainerRef, Input, OnDestroy, HostListener } from '@angular/core';
import { ProductCodeQuery } from '@fgb/core';
import { Subscription } from 'rxjs';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[notInProductCode]'
})
export class NotInProductCodeDirective implements OnDestroy {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private productCodeQuery: ProductCodeQuery
  ) {}
  private prodSub: Subscription;

  @Input() set notInProductCode(productCodes: string[] | string[][]) {
    let flatProductCodes: string[] = [];
    /** If productCodes are passed as a multidimensional array, flatten them into a single array */
    if (productCodes[0] !== undefined && productCodes[0].constructor === Array) {
      flatProductCodes = (productCodes as string[][]).reduce((accumulator, value) => accumulator.concat(value), []);
    } else {
      flatProductCodes = productCodes as string[];
    }

    this.prodSub = this.productCodeQuery.selectCurrentUserIsNotProductCode(flatProductCodes).subscribe((p) => {
      this.viewContainer.clear();
      if (p) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    if (this.prodSub) {
      this.prodSub.unsubscribe();
    }
  }
}
