import { Component, OnInit } from '@angular/core';
import { PartnerQuery, Partner, PartnerService, PartnerPendingService, PartnerPending, PartnerPendingQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss'],
})
export class PartnerListComponent implements OnInit {
  constructor(
    private pendingQuery: PartnerPendingQuery,
    private partnerQuery: PartnerQuery,
    private partnerService: PartnerService,
    private pendingService: PartnerPendingService
  ) {}
  approvedPartners$: Observable<Partner[]>;
  pendingPartners$: Observable<PartnerPending[]>;
  partnerName: String;
  partnerEmail: String;

  ngOnInit() {
    this.approvedPartners$ = this.partnerQuery.selectAll();
    this.pendingPartners$ = this.pendingQuery.selectAll();
  }

  delete(id: number) {
    this.partnerService.remove(id).toPromise();
  }

  deletePending(ticketKey: string) {
    this.pendingService.remove(ticketKey).toPromise();
  }
  selectPartner(FullName: string, Email: string) {
    this.partnerName = FullName;
    this.partnerEmail = Email;
  }
}
