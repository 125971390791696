<div class="d-none d-lg-block">
  <div *ngIf="marketplaceItem$ | async">
    <fgb-banner [title]="marketplaceItemType.group"></fgb-banner>
  </div>

  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar
    [backButtonLink]="marketplaceItemType.backLink"
    [marketplaceItemGroup]="marketplaceItemType.group"
  ></fgb-points-summary-bar>
</div>

<fgbcl-marketplace-details [itemId]="id" [actionLabels]="actionLabels"></fgbcl-marketplace-details>

<div class="container">
  <div
    class="text-primary pt-3 cursor-pointer d-none d-lg-flex align-items-center back-btn"
    [routerLink]="[marketplaceItemType.backLink]"
  >
    <span class="material-icons"> keyboard_arrow_left </span>
    <span class="font-size-14 font-weight-bold"> {{ 'back.to' | translate }} {{ marketplaceItemType.group }}</span>
  </div>
</div>
