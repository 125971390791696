import { Component, OnInit } from '@angular/core';
import { ChildQuery, ChildViewModel, PaymentOptionType } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { tap } from 'rxjs/operators';
import { UpgradeChildrenComponent } from '../upgrade-children/upgrade-children.component';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';

@Component({
  selector: 'fgb-child-list',
  templateUrl: 'child-list.component.html',
  styleUrls: ['child-list.component.scss'],
})
export class ChildListComponent implements OnInit {
  public childLimit: number = 10;
  public emptyChildren: any[];

  children$: Observable<ChildViewModel[]>;
  freeChildren: ChildViewModel[];

  customOptions: OwlOptions = {
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      400: { items: 1 },
      740: { items: 3 },
    },
  };

  constructor(private childQuery: ChildQuery, private modalService: NgbModal) {}

  ngOnInit() {
    this.children$ = this.childQuery.selectChildren().pipe(
      tap((x) => {
        this.emptyChildren = new Array(this.childLimit - x.length);
        this.freeChildren = x.filter((y) => y.MembershipOption.PaymentType === PaymentOptionType.Free);
      })
    );
  }

  addChildren() {
    console.log('adding children not implemented');
  }

  upgradeChildren() {
    const modal = this.modalService.open(UpgradeChildrenComponent, { ariaLabelledBy: 'modal-basic-title', size: 'lg' });
    modal.componentInstance.children = this.freeChildren;
  }
}
