import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MarketplaceExtendedQuery, MarketplaceItemExtended, MarketplaceItemStatus, ProcessProviderId } from '@fgb/core';
import { SortBarOption, SortOrder } from 'src/app/shared/components/sort-bar/sort-bar-option.enum';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';

@Component({
  selector: 'fgb-marketplace-list',
  templateUrl: './marketplace-list.component.html',
  styleUrls: ['./marketplace-list.component.scss'],
})
export class MarketplaceListComponent implements OnInit, OnDestroy {
  @Input() take: number;
  @Input() isList: boolean;
  @Input() marketplaceItemTypes: ProcessProviderId[];
  @Input() marketplaceItemView: boolean;
  @Input() eventItemView: boolean;
  @Input() productsItemView: boolean;

  googleIcons = IconSetting.googleIcon;

  sortBarOptions: SortBarOption[];
  sortOrderType = SortOrder;

  marketplaceItems$: Observable<MarketplaceItemExtended[] | undefined>;
  marketplaceItemsSubscription: Subscription;
  filteredMarketplaceItems: MarketplaceItemExtended[];

  constructor(private marketplaceExtendedQuery: MarketplaceExtendedQuery) {}

  ngOnInit() {
    this.queryMarketplaceItems();
    this.subscribeAndSortMarketplaceItems();
    this.defineSortBarOptions();
  }

  ngOnDestroy() {
    if (this.marketplaceItemsSubscription) {
      this.marketplaceItemsSubscription.unsubscribe();
    }
  }

  queryMarketplaceItems() {
    if (this.marketplaceItemTypes) {
      this.marketplaceItems$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItemsOfType(...this.marketplaceItemTypes);
    } else {
      this.marketplaceItems$ = this.marketplaceExtendedQuery.selectExtendedMarketplaceItems();
    }
  }

  subscribeAndSortMarketplaceItems() {
    this.marketplaceItemsSubscription = this.marketplaceItems$.subscribe((items) => {
      if (items) {
        items.filter((item) => !item.ComingSoon || (item.ComingSoon && new Date(item.ItemEndDate) > new Date()));
        items = this._sortItems(items);
        this.filteredMarketplaceItems = items;
      }
    });
  }

  defineSortBarOptions() {
    this.sortBarOptions = [
      {
        propertyToSort: 'PointValue',
        shouldToggle: false,
        order: this.sortOrderType.Descending,
        labelDESC: 'Points high to low',
      },
      {
        propertyToSort: 'PointValue',
        shouldToggle: false,
        order: this.sortOrderType.Ascending,
        labelASC: 'Points low to high',
      },
      {
        propertyToSort: 'ItemEndDate',
        order: this.sortOrderType.Ascending,
        shouldToggle: true,
        labelASC: 'Ending Soon',
        labelDESC: 'New',
      },
      {
        propertyToSort: 'AvailableCount',
        order: this.sortOrderType.Descending,
        shouldToggle: true,
        labelASC: 'Limited Availability',
        labelDESC: 'High Availability',
      },
      {
        propertyToSort: 'DisplayName',
        order: this.sortOrderType.Descending,
        shouldToggle: true,
        labelASC: 'Name A to Z',
        labelDESC: 'Name Z to A',
      },
    ];
  }

  resetOrder() {
    this.subscribeAndSortMarketplaceItems();
  }

  private _sortItems(items: MarketplaceItemExtended[]): MarketplaceItemExtended[] {
    let sortedItemsMap = new Map<string, MarketplaceItemExtended[]>();

    // split out for all marketplace status
    for (let status in MarketplaceItemStatus) {
      if (isNaN(Number(status))) {
        sortedItemsMap.set(
          status,
          items
            .filter((x) => x.Status === (MarketplaceItemStatus as any)[status])
            .sort((a: MarketplaceItemExtended, b: MarketplaceItemExtended) => b.PointValue - a.PointValue)
        );
      }
    }

    let sortedItems: MarketplaceItemExtended[] = [];

    let availableItems = sortedItemsMap.get(MarketplaceItemStatus[MarketplaceItemStatus.Available]);
    if (availableItems) {
      sortedItems.push(...availableItems);
    }

    let notEnoughPointsItems = sortedItemsMap.get(MarketplaceItemStatus[MarketplaceItemStatus.NotEnoughPoints]);
    if (notEnoughPointsItems) {
      sortedItems.push(...notEnoughPointsItems);
    }

    let soldOutItems = sortedItemsMap.get(MarketplaceItemStatus[MarketplaceItemStatus.SoldOut]);
    if (soldOutItems) {
      sortedItems.push(...soldOutItems);
    }

    let comingSoonItems = sortedItemsMap.get(MarketplaceItemStatus[MarketplaceItemStatus.ComingSoon]);
    if (comingSoonItems) {
      sortedItems.push(...comingSoonItems);
    }

    return sortedItems;
  }
}
