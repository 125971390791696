import { Component, OnInit, TemplateRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'fgb-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  imageRef: TemplateRef<any>;
  modalContent: TemplateRef<any>;
  confirm = new EventEmitter<string>();
  close = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}
}
