/**
 * Options available for the fgb-sort-bar component. 
 */
 export type SortBarOption = {
  /** Property of the object that should be sorted */
  propertyToSort: string,
  /** Sorting order: SortOrder.Ascending or SortOrder.Descending. If toggleState is true, default order. */
  order: SortOrder,
  /** Enables toggle between Ascending and Descending on the same button.*/
  shouldToggle: boolean,
  /** Label displayed to sort in ascending order */
  labelASC?: string,
  /** Label displayed to sort in descending order */
  labelDESC?: string
}

export enum SortOrder {
  Ascending = 1,
  Descending = -1,
}
