import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconSetting } from 'src/app/shared/utilities/icon-setting';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.scss'],
})
export class AccountNavComponent implements OnInit, OnDestroy {
  googleIcons = IconSetting.googleIcon;
  partnerDisabled: boolean = false;

  private _destroyed$ = new Subject();

  @Input() isMarginBottom: boolean = true;
  productCode = ProductCode;
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.checkpartnerDisabledFlag();
  }

  checkpartnerDisabledFlag() {
    this.translate
      .stream('partner.disabled.flag')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        if (data === 'true') {
          this.partnerDisabled = JSON.parse(data);
        }
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
