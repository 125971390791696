<div class="card">
  <div *ngIf="member$ | async as member">
    <div class="">
      <label class="mb-0 personal-info mt-0">{{ 'account.details.dob' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ml-3">{{ member.DOB | date : 'MMM dd' }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">{{ 'account.details.contact.no' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">call</span>
        <span class="ml-3">{{ member.MobileNumber }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">{{ 'account.details.email' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ml-3">{{ member.EmailAddress1 }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 personal-info">{{ 'account.details.main.address' | translate }}</label>
      <div class="d-flex bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons d-inline-block" aria-hidden="true">location_on</span>
        <span class="ml-3 d-inline-block">
          <div>{{ member.AddressName }}</div>
          <div>{{ member.State }}</div>
          <div>{{ member.Country }}</div>
          <div>{{ member.Town }}</div>
          <div>{{ member.PostCode }}</div>
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
