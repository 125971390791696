import { Component, Input, OnInit } from '@angular/core';
import { MemberDetails, MemberQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() title = 'Welcome';
  @Input() isHomePage: boolean = false;
  memberDetails$: Observable<MemberDetails | undefined>;
  constructor(private memberQuery: MemberQuery) {}

  ngOnInit(): void {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
  }
}
