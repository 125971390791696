import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Partner, PartnerQuery } from '@fgb/core';
import { take } from 'rxjs/operators';
@Component({
  selector: 'fgb-points-transfer-success-page',
  templateUrl: './points-transfer-success-page.component.html',
  styleUrls: ['./points-transfer-success-page.component.scss'],
})
export class PointsTransferSuccessPageComponent implements OnInit {
  partner: Partner | undefined;
  amount: any;
  constructor(private partnerQuery: PartnerQuery, private route: ActivatedRoute,private router:Router) {}

  ngOnInit(): void {
    this.listenRoute();
  }

  listenRoute() {
    this.route.queryParams.pipe(take(1)).subscribe((response: any) => {
      this.amount = response?.amount;
      this.partner = this.getPartner(response?.idPartner);
    });
  }

  getPartner(idPartner: string) {
    return this.partnerQuery.getEntity(idPartner);
  }

  backToPointsTransfer(){
    this.router.navigateByUrl('pointsmanagement');
  }
}
