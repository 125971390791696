import { Component, OnInit, Input } from '@angular/core';
import { SurveyType } from '@fgb/core';
import { Survey } from '@fgb/core';

@Component({
  selector: 'fgb-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.scss', '../survey.scss'],
})
export class SurveyItemComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  surveyType = SurveyType;

  constructor() {}

  ngOnInit() {}
}
