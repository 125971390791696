<div class="d-lg-block d-none">
  <fgb-banner [title]="'events.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3">
  <fgb-member-card></fgb-member-card>
</div>
<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-marketplace-list [isList]="true" [marketplaceItemTypes]="[3, 5]" [eventItemView]="true"></fgbcl-marketplace-list>
</div>
