import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';

import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit, OnChanges {
  /** The end date time for the countdown in UTC format. */
  @Input() endDateTime: string;

  /** The time that the end date was requested - used to return time remaining more accurately than using moment.utc */
  @Input() requestDateTime: string = '';

  /** The text to display in the countdown upon expiry. */
  @Input() completeText: string = 'Complete';

  /** determines whether component will receive local or utc time. default is utc. */
  @Input() isUTC: boolean = true;

  /** Emitted upon countdown expiry. */
  @Output() complete = new EventEmitter<any>();

  formattedTime$: Observable<string>;
  daysRemaining: number;
  completed = false;

  private endDate: _moment.Moment;
  private requestDate: _moment.Moment;

  constructor() {}

  ngOnInit(): void {
    this.setTimer(this.endDateTime);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.endDateTime) {
      this.setTimer(changes.endDateTime.currentValue);
    }
  }

  /**
   * Sets the countdown timer relative to the supplied endDateTime.
   * @param endDateTime The time that the countdown should end as a UTC date.
   */
  setTimer(endDateTime: string): void {
    this.endDate = this.isUTC ? moment.utc(endDateTime) : moment.utc(new Date(endDateTime).toUTCString());
    this.setRequestDate();

    if (this.endDate < this.requestDate) {
      this.completed = true;
      this.complete.emit(this.completed);
      this.formattedTime$ = of(this.completeText);
    } else {
      this.completed = false;
      this.formattedTime$ = timer(0, 1000).pipe(
        // convert interval into remaining time in seconds
        map(() => {
          this.setRequestDate();
          let utcNow = this.requestDate;
          return Math.floor(this.endDate.diff(utcNow, 'seconds'));
        }),
        // stop emission after complete
        filter(() => !this.completed),
        // emit on complete
        tap((t) => {
          if (t <= 0) {
            this.completed = true;
            this.complete.emit(this.completed);
          }
        }),
        map(this.formatRemainingTime)
      );
    }
  }

  private setRequestDate(): void {
    this.requestDate = this.requestDateTime.length > 0 ? moment(this.requestDateTime) : moment.utc();
    this.requestDate = this.isUTC ? moment.utc(this.requestDate) : moment.utc();
  }

  formatRemainingTime(seconds: number): string {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor(seconds / 60);

    if (days) {
      const remainderHours = Math.floor((seconds % 86400) / 3600);
      return `${days}d ${remainderHours}h`;
    } else if (hours) {
      const remainderMinutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${remainderMinutes}m`;
    } else if (minutes) {
      const remainderSeconds = seconds % 60;
      return `${minutes}m ${remainderSeconds}s`;
    } else {
      return `${seconds}s`;
    }
  }
}
