<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <div class="member-card" [ngClass]="[memberCard.ProductCode | background, accountPage ? 'card-height-auto' : 'card-height']">
    <div class="d-flex flex-column">
      <div class="mb-2 d-flex align-items-center justify-content-between" [ngClass]="{ 'mb-lg-4': accountPage }">
        <!--Name and ID-->
        <div class="card-id text-left w-100 p-0">
          <h5
            class="pt-2 mb-1 font-size-14 font-weight-bold card-name w-100"
            *ngIf="memberDetails$ | async as memberDetails"
            [ngClass]="{ 'text-black': memberCard.ProductCode == productCode.Diamond }"
          >
            {{ memberDetails.FirstName }} <br />
            {{ memberDetails.Surname }}
          </h5>
          <div class="d-flex font-size-12">
            <div class="product-code" [ngClass]="{ 'text-black': memberCard.ProductCode == productCode.Diamond }">
              {{ memberCard.ProductCode }} - {{ memberCard.ExternalRefNumber }}
            </div>
          </div>
        </div>
      </div>
      <ng-container *inProductCode="[productCode.Diamond, productCode.Exclusive]">
        <hr class="bg-black my-2" />
      </ng-container>
      <ng-container *notInProductCode="[productCode.Diamond, productCode.Exclusive]">
        <hr class="my-2" />
      </ng-container>

      <!--Balances-->
      <ng-container *notInProductCode="productCode.Trial_Member">
        <div class="d-flex align-items-end">
          <div class="card-account-no text-left border-white pr-5 mt-lg-4 mt-3" *ngIf="pointsBalance$">
            <label class="font-size-14 font-weight-bold mb-1">{{ pointsBalance$ | async | number }}</label>
            <div class="font-size-12 mb-1">{{ 'member.card.points.balance' | translate }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
