<!-- Banner -->
<div class="d-none d-lg-block">
  <fgb-banner [title]="'auction.page.title' | translate"></fgb-banner>

  <!-- Nav Bar -->
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<!-- Auction Image Mobile -->
<div class="container mt-3 px-lg-0">
  <a href="https://orioles.auctions.mlb.com/" target="_blank">
    <ng-container *ifScreenSize="screenType.Mobile">
      <img src="assets/images/rewards/MobileAuctionsBanner.jpg" class="w-100" />
    </ng-container>
  </a>
</div>

<!-- Points Summary -->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards/auctions'" [backButtonTitle]="'Auctions'"></fgb-points-summary-bar>
</div>

<!-- Auction Image Desktop -->
<div class="container mt-lg-3 px-lg-0">
  <div class="container">
    <a href="https://orioles.auctions.mlb.com/" target="_blank">
      <ng-container *ifScreenSize="screenType.Desktop">
        <img src="assets/images/rewards/DesktopAuctionsBanner.jpg" class="w-100" />
      </ng-container>
    </a>
  </div>
</div>

<!-- Auction Details -->
<fgbcl-auction-details [auctionId]="id"></fgbcl-auction-details>

<!-- Back Button -->
<div class="container">
  <div class="text-primary pt-3 cursor-pointer d-none d-lg-flex align-items-center" [routerLink]="'/rewards/auctions'">
    <span class="material-icons"> keyboard_arrow_left </span>
    <span class="font-size-14 font-weight-bold">{{ 'rewards.back.to.auctions' | translate }}</span>
  </div>
</div>
