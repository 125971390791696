<ng-container *ngIf="partners$ | async as partners">
  <ng-conatiner *ngIf="partners.length; else noPartners">
    <div class="card mt-3" [formGroup]="transferPointsForm">
      <div class="card-body p-3 points-transfer">
        <h4 class="title text-uppercase mb-3">{{ 'point.transfer.title' | translate }}</h4>
        <div class="points-transfer-header font-size-14 mt-2">
          <div class="text-left mb-3" [innerHTML]="'point.transfer.card.title.1' | translate"></div>
        </div>
        <div class="row align-items-center justify-content-center px-2 px-lg-0">
          <div class="form-partner row">
            <div class="select-partners col-12 col-lg-9">
              <label>{{ 'point.transfer.my.partner' | translate }}</label>
              <select class="form-control" formControlName="partnerSelected">
                <option selected="true" hidden [value]="null">{{ 'select.partner.placeholder' | translate }}</option>
                <option *ngFor="let partner of partners" [ngValue]="partner">{{ partner.FullName }} ({{ partner.Email }})</option>
              </select>
            </div>
            <div class="points mt-3 mt-lg-0 col-12 col-lg-3">
              <label>{{ 'point.transfer.points' | translate }}</label>
              <div class="amount-counter row d-flex d-lg-block align-items-center justify-content-between">
                <div class="number-input col-12 mb-4 mb-lg-0">
                  <fgbcl-number-input
                    [isActive]="true"
                    [minValue]="1"
                    [maxValue]="balance"
                    [incrementValue]="1"
                    [theme]="auction"
                    [(amount)]="amount"
                    [ngClass]="{ 'is-invalid': !!transferPointsForm.controls.Amount.errors }"
                    [minMessage]="'1 point is the minimum'"
                    [maxMessage]="'You do not have enough points'"
                  >
                  </fgbcl-number-input>
                </div>
                <div class="text-center d-block d-lg-none col-12 col-lg-3">
                  <button (click)="send()" [disabled]="transferPointsForm.invalid" class="btn btn-primary send-button">
                    {{ 'point.transfer.send' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-left d-none d-lg-block mt-4">
          <button (click)="send()" [disabled]="transferPointsForm.invalid" class="btn btn-primary send-button">
            {{ 'point.transfer.send' | translate }}
          </button>
        </div>

        <!-- Confirm modal -->
        <ng-template #confirmModal>
          <div class="points-transfer-modal font-size-12">
            <div class="font-size-14 text-center font-weight-bold mb-4">
              {{ 'point.transfer.confirm.text' | translate }}
            </div>
            <hr />
            <div class="partner-details mb-2">
              <span>
                <b class="mr-2">{{ 'point.transfer.person.detail' | translate }}</b>
                {{ transferPointsForm.controls.partnerSelected.value.FullName }} ({{
                  transferPointsForm.controls.partnerSelected.value.Email
                }})
              </span>
            </div>
            <div class="points-value mb-5">
              <span>
                <b class="mr-2">{{ 'point.transfer.point.value' | translate }}</b> {{ transferPointsForm.controls.Amount.value }}
              </span>
            </div>

            <div class="text-center">
              <button type="button" class="btn text-white btn-cancel font-weight-bold float-left" (click)="closeModal()">
                {{ 'point.transfer.cancel' | translate }}
              </button>
              <button type="button" class="btn text-white btn-confirm font-weight-bold float-right" (click)="transferPoints()">
                {{ 'point.transfer.confirm' | translate }}
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-conatiner>
  <ng-template #noPartners>
    <div class="card shadow text-center p-3">
      <div class="font-weight-bold font-size-14">{{ 'point.transfer.no.partner' | translate }}</div>
    </div>
  </ng-template>
</ng-container>
