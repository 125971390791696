import { Component, Input, OnInit } from '@angular/core';
import { MixedLoyaltyItemType, ProcessProviderId, RewardsWalletService, RewardsWalletItem, RewardsWalletQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  screenType = ScreenType;
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;

  constructor(private rewardWalletItemsService: RewardsWalletService, private rewardWalletQuery: RewardsWalletQuery) {}

  ngOnInit() {
    this.rewardWalletItemsService.fetchWalletItems().toPromise();
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);
  }
}
