import { Component, OnInit } from '@angular/core';
import { AnnouncementService, GameService, Survey, SurveyQuery, SurveyType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { ProductCode } from 'src/app/shared/utilities/product-code';

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  polls$: Observable<Survey[]>;
  screenSize = ScreenType;
  customOptions: OwlOptions = {
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      740: { items: 2 },
      900: { items: 3 },
    },
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: false },
      740: { autoWidth: true, autoHeight: false },
    },
  };
  productCode = ProductCode;
  constructor(
    private announcementService: AnnouncementService,
    private surveyQuery: SurveyQuery,
    private gameService: GameService
  ) {}

  ngOnInit() {
    this.polls$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Poll);

    this.announcementService.fetchAnnouncements().toPromise();
    this.gameService.fetchGames().toPromise();
  }
}
