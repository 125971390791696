<div class="d-block d-lg-flex">
  <div *ngIf="imageRef" class="modal-image flex-grow-0 flex-shrink-0">
    <ng-container [ngTemplateOutlet]="imageRef"></ng-container>
  </div>

  <div class="d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1">
    <div class="modal-body">
      <ng-container [ngTemplateOutlet]="modalContent"></ng-container>
    </div>

    <div class="modal-footer d-flex justify-content-between border-0">
      <button type="button" class="btn btn-danger font-weight-bold" (click)="close.emit('close')">
        Cancel
      </button>
      <button type="button" class="btn btn-success font-weight-bold" (click)="confirm.emit('confirm')">
        Confirm
      </button>
    </div>
  </div>
</div>
