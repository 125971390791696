<div class="d-lg-block d-none">
  <fgb-banner [title]="'wallet.page.title' | translate"></fgb-banner>
</div>
<div class="container d-lg-none d-block">
  <div class="pt-3 mb-3 mb-lg-0">
    <fgb-member-card></fgb-member-card>
  </div>
</div>

<ng-container *ifScreenSize="screenType.Desktop">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</ng-container>

<!-- Campaign Offers -->
<div class="mb-4">
  <fgbcl-campaigns-offers-list></fgbcl-campaigns-offers-list>
</div>

<!-- V2 Wallet -->
<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-3 mb-3">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border rounded">
      <div class="no-wallet-items-description font-weight-bold font-size-12 text-center py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text font-weight-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
