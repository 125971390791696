<a [owlRouterLink]="'/rewards/marketplace/details/' + marketplaceItem.Id" [stopLink]="ignoreLink">
  <div class="card loyalty border-0 shadow-sm">
    <div>
      <img
        class="card-img-top"
        [src]="marketplaceItem.ItemImageURL | contentImage: '7':'marketplace'"
        [alt]="marketplaceItem.DisplayName"
        [useDefault]="true"
        [loyaltyType]="'marketplace'"
        draggable="false"
      />
      <div class="corner-icon-container {{ 'bg-' + marketplaceItem.theme }} {{ 'text-on-' + marketplaceItem.theme }}">
        <fgb-loyalty-icon class="corner-icon" [loyaltyType]="marketplaceItem.ProcessProviderId"></fgb-loyalty-icon>
      </div>
      <div class="card-img-overlay bg-loyalty-gradient text-white px-3 pb-2 pt-3 rounded-0">
        <div class="mb-3 font-weight-bold">{{ marketplaceItem.DisplayName }}</div>
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <ng-container *ngIf="googleIcons; else basicIconPoints">
              <span class="icon material-icons currency-icons pr-1">emoji_events</span>
            </ng-container>
            <ng-template #basicIconPoints>
              <span class="icon icon-trophy-1 font-size-18 pr-2"></span>
            </ng-template>
            <span class="pl-1 font-weight-normal">{{ marketplaceItem.PointValue | FortressCurrencyPipe: 'PTS' }}</span>
          </div>
          <div class="col-6 d-flex align-items-center">
            <span class="icon material-icons pr-1">sell</span>
            <span class="pl-1 font-weight-normal">{{ marketplaceItem.AvailabilityCount }}/{{ marketplaceItem.StockCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cta {{ 'bg-' + theme }} {{ 'text-on-' + theme }}">
      <span class="mx-1 font-weight-bold">{{ statusText | translate }}</span>
    </div>
  </div>
</a>
