<div *ngIf="purchasedItem; else noPurchase" class="container">
  <div class="d-block d-lg-flex border rounded overflow-hidden mt-lg-5 mt-3">
    <!-- Details -->
    <div class="item-details d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 px-3 pt-3">
      <div>
        <div class="text-success mb-0 font-weight-bold text-uppercase font-size-14 text-center">
          <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
            'marketplace.purchase.success' | translate
          }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Event'">{{ 'events.purchase.success' | translate }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Product'">{{ 'products.purchase.success' | translate }}</ng-container>
        </div>
        <hr class="mt-3 mx-5 mb-2" />
        <p class="mb-0 text-black font-size-14 text-center">
          <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
            'marketplace.purchase.email.confirmation' | translate
          }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Event'">{{
            'events.purchase.email.confirmation' | translate
          }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Product'">{{
            'products.purchase.email.confirmation' | translate
          }}</ng-container>
        </p>
        <div class="row my-4">
          <div class="col-lg-1 col-2 d-flex justify-content-left">
            <img
              class="purchase-success-image rounded"
              src="{{ purchasedItem.ItemImageURL | contentImage : '7' : 'marketplace' }}"
              alt="{{ purchasedItem.ImageURL }}"
              [useDefault]="true"
              loyaltyType="marketplace"
              draggable="false"
            />
          </div>
          <div class="col-lg-10 col-10">
            <div class="pl-lg-3 pl-1">
              <div class="font-size-12 mb-1">{{ purchasedItem.DisplayName }}</div>
              <div class="row">
                <ng-container *ngFor="let attribute of attributes">
                  <div class="col-12 mt-1 mb-2">
                    <div class="font-size-12 mb-0">
                      <span class="font-weight-bold">{{ attribute.AttributeName }}</span>
                      <span> {{ attribute.ValueName }}</span>
                    </div>
                  </div>
                </ng-container>

                <div class="col-4 col-lg-3">
                  <div class="font-size-12 mb-0">
                    <span class="font-weight-bold pr-2">{{ 'marketplace.quantity' | translate }}</span>
                    <span>{{ quantity }}</span>
                  </div>
                </div>
                <div class="col-8 col-lg-5">
                  <div class="font-size-12 mb-0">
                    <span class="font-weight-bold pr-2">{{ 'marketplace.points.used' | translate }}</span>
                    <span>{{ price | number }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="shipping col-12 mt-4">
            <div class="font-weight-bold font-size-12 mb-2">{{ 'marketplace.shipping.label' | translate }}</div>
            <div class="font-size-14" [innerHTML]="'marketplace.shipping.info' | translate | markdownTranslate"></div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between border-0 flex-column flex-lg-row">
        <a class="btn btn-primary text-white confirm-btn font-weight-bold" [routerLink]="['/rewards/marketplace']">{{
          'rewards.back.to.rewards' | translate
        }}</a>
        <a class="btn btn-back-to-wallet confirm-btn font-weight-bold" [routerLink]="['/rewards/wallet']">{{
          'rewards.back.to.wallet' | translate
        }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">No purchased item found...</div>
</ng-template>
