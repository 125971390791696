<div class="card bg-primary text-on-primary shadow">
  <div class="card-body p-3">
    <div class="d-flex align-items-center">
      <div class="d-inline-block pr-2">
        <div class="svg-user svg activity font-weight-bold" [inlineSVG]="'assets/images/icons/account-icon.svg'"></div>
      </div>
      <div class="child-panel d-inline-block">
        <div class="m-0 font-weight-bold font-size-14">{{ child.Firstname }} {{ child.Lastname }}</div>
        <p class="m-0 font-size-12">
          <ng-container *ngIf="child.MembershipOptionId === 1"> All Star </ng-container>
          <ng-container *ngIf="child.MembershipOptionId === 0"> Rookie </ng-container>
        </p>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-5">
        <label class="mb-0">Points</label>
        <div *ngIf="loyaltyPointsCard$">
          <span class="icon icon-trophy-1 mr-1"></span>
          <span class="font-size-14">
            {{ loyaltyPointsCard$ | async }}
          </span>
        </div>
      </div>
      <div class="col-7">
        <label class="mb-0">Date of Birth</label>
        <span class="icon icon-calendar mr-1"></span>
        <span class="font-size-14">
          {{ child.DateOfBirth | date }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="d-lg-flex d-none justify-content-center p-2 shadow login-panel">
  <button type="button" (click)="loginToChild()" class="btn btn-primary font-weight-bold font-weight-bold text-white">
    Login
  </button>
</div>

<div class="d-flex d-lg-none justify-content-between p-2 shadow bg-white rounded-bottom login-kids-panel">
  <button
    type="button"
    (click)="loginToChild()"
    class="btn btn-primary mobile-child-login-btn font-weight-bold text-white font-size-14"
  >
    Login
  </button>

  <button
    type="button"
    (click)="openChildBarcode()"
    class="btn btn-primary mobile-child-login-btn font-weight-bold text-white font-size-14"
  >
    Show Barcode
  </button>
</div>
