import { Component, OnInit, Input } from '@angular/core';
import { ProcessProviderId } from '@fgb/core';
import { IconSetting } from '../../utilities/icon-setting';

@Component({
  selector: 'fgb-loyalty-icon',
  templateUrl: './loyalty-icon.component.html',
  styleUrls: ['./loyalty-icon.component.scss'],
})
export class LoyaltyIconComponent implements OnInit {
  googleIcons = IconSetting.googleIcon;
  @Input() loyaltyType: 'auction' | 'lotto' | 'shipping' | 'wallet' | 'walletRedeemed' | ProcessProviderId;
  gIcon: string;
  icon: string;

  private gIconAuction = 'local_activity';
  private gIconLotto = 'confirmation_number';
  private gIconMarketplace = 'shopping_bag';
  private gIconWallet = 'account_balance_wallet';
  private gIconShipping = 'local_shipping';
  private gIconWalletRedeemed = 'shopping_bag';
  private gIconMarketplaceFortressGb = 'shopping_bag';
  private gIconMarketplaceUniqueCode = 'qr_code';
  private gIconMarketplaceDownload = 'file_download';
  private gIconMarketplaceEvent = 'local_activity';
  private gIconMarketplacePortalProduct = 'local_atm';
  private gIconMarketplaceVirtualEvent = 'local_activity';

  private iconAuction = 'icon-auction';
  private iconLotto = 'icon-medal-4';
  private iconMarketplace = 'icon-handbag';
  private iconWallet = 'icon-wallet-loaded';
  private iconWalletRedeemed = 'icon-handbag';
  private iconMarketplaceFortressGb = 'icon-handbag';
  private iconMarketplaceUniqueCode = 'icon-download-2';
  private iconMarketplaceDownload = 'icon-barcode';
  private iconMarketplaceEvent = 'icon-calendat-time';
  private iconMarketplacePortalProduct = 'icon-money-bag-coins';
  private iconMarketplaceVirtualEvent = 'icon-calendat-time';

  constructor() {}

  ngOnInit() {
    if ((this.googleIcons = true)) {
      switch (this.loyaltyType) {
        case 'auction':
          this.gIcon = this.gIconAuction;
          break;
        case 'lotto':
          this.gIcon = this.gIconLotto;
          break;
        case 'shipping':
          this.gIcon = this.gIconShipping;
          break;
        case 'wallet':
          this.gIcon = this.gIconWallet;
          break;
        case 'walletRedeemed':
          this.gIcon = this.gIconWalletRedeemed;
          break;
        case ProcessProviderId.FortressGB:
          this.gIcon = this.gIconMarketplaceFortressGb;
          break;
        case ProcessProviderId.UniqueCode:
          this.gIcon = this.gIconMarketplaceUniqueCode;
          break;
        case ProcessProviderId.Download:
          this.gIcon = this.gIconMarketplaceDownload;
          break;
        case ProcessProviderId.Event:
          this.gIcon = this.gIconMarketplaceEvent;
          break;
        case ProcessProviderId.Product:
          this.gIcon = this.gIconMarketplacePortalProduct;
          break;
        case ProcessProviderId.VirtualEvent:
          this.gIcon = this.gIconMarketplaceVirtualEvent;
          break;
        default:
          this.gIcon = this.gIconMarketplace;
          break;
      }
    } else {
      switch (this.loyaltyType) {
        case 'auction':
          this.icon = this.iconAuction;
          break;
        case 'lotto':
          this.icon = this.iconLotto;
          break;
        case 'wallet':
          this.icon = this.iconWallet;
          break;
        case 'walletRedeemed':
          this.icon = this.iconWalletRedeemed;
          break;
        case ProcessProviderId.FortressGB:
          this.icon = this.iconMarketplaceFortressGb;
          break;
        case ProcessProviderId.UniqueCode:
          this.icon = this.iconMarketplaceUniqueCode;
          break;
        case ProcessProviderId.Download:
          this.icon = this.iconMarketplaceDownload;
          break;
        case ProcessProviderId.Event:
          this.icon = this.iconMarketplaceEvent;
          break;
        case ProcessProviderId.Product:
          this.icon = this.iconMarketplacePortalProduct;
          break;
        case ProcessProviderId.VirtualEvent:
          this.icon = this.iconMarketplaceVirtualEvent;
          break;
        default:
          this.icon = this.iconMarketplace;
          break;
      }
    }
  }
}
