import { Directive, Input, TemplateRef, Output, HostListener, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from '../components/confirm-modal/confirm-modal.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[confirmModal]',
})
export class ConfirmModalDirective implements OnDestroy, OnChanges {
  constructor(public modalService: NgbModal) {}

  @Input() confirmImage: TemplateRef<any>;
  @Input() confirmContent: TemplateRef<any>;
  @Input() valid: boolean = true;
  @Output() confirm = new EventEmitter();
  modal: NgbModalRef;
  modalComponent: ConfirmModalComponent;
  destroy$ = new Subject<any>();

  @HostListener('click') onClick() {
    this.modal = this.modalService.open(ConfirmModalComponent, { size: !!this.confirmImage ? 'lg' : undefined, centered: true });
    this.modalComponent = this.modal.componentInstance as ConfirmModalComponent;
    this.modalComponent.modalContent = this.confirmContent;
    this.modalComponent.imageRef = this.confirmImage;
    this.modalComponent.close.pipe(takeUntil(this.destroy$)).subscribe((x) => this.modal.close(x));
    this.modalComponent.confirm.pipe(takeUntil(this.destroy$)).subscribe((x) => {
      this.confirm.emit(x);
      this.modal.close(x);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.valid) {
      this.modal.close();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
