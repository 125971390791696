<form [formGroup]="acceptForm" class="row mt-3" (ngSubmit)="submit()">
  <div class="row w-100">
    <div class="d-flex align-items-center justify-content-center w-100">
      <span class="icon material-icons mr-2"> task_alt </span>
      <h5 class="text-center mb-0">{{ 'partner.accept.form.title' | translate }}</h5>
    </div>
  </div>

  <div class="px-2 row w-100">
    <hr class="partner-divider w-100" />
    <div class="col-12">
      <span class="font-size-14">{{ 'partner.accept.form.description' | translate }} </span>
    </div>
    <div class="col-12 mt-3">
      <label>{{ 'partner.accept.form.code.label' | translate }}</label>
      <input formControlName="code" placeholder="Insert Code Here" class="form-control bg-muted" />
    </div>
    <div class="col-12 mt-3">
      <label>{{ 'partner.accept.form.secret.code.label' | translate }}</label>
      <input formControlName="secretCode" placeholder="Insert Secret Code Here" class="form-control bg-muted" />
    </div>
    <div class="mt-2 px-2 error-message">
      <fgbcl-errors></fgbcl-errors>
    </div>
  </div>

  <div class="button-container d-flex justify-content-between form-buttons mb-3 w-100">
    <button (click)="close(); $event.preventDefault()" class="btn btn-danger font-weight-bold ml-3">
      {{ 'partner.accept.form.cancel.button' | translate }}
    </button>

    <button type="submit" [disabled]="acceptForm.invalid" class="btn btn-success font-weight-bold mr-3">
      {{ 'partner.accept.form.submit.button' | translate }}
    </button>
  </div>
</form>
