import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsComponent } from './components/next-game/next-game.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { GamesListWithTicketsComponent } from './components/games-list-with-tickets/games-list-with-tickets.component';
import { RouterModule } from '@angular/router';
import { EventsDropDownComponent } from './components/events-drop-down/events-drop-down.component';
import { FormsModule } from '@angular/forms';
import { GameBannerComponent } from './components/game-banner/game-banner.component';
import { GameCardComponent } from './components/game-card/game-card.component';
import { GameListComponent } from './components/game-list/game-list.component';
import { TicketTransferWindowStatusBarComponent } from './components/games-list-with-tickets/ticket-transfer-window-status-bar/ticket-transfer-window-status-bar.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { InlineSVGModule } from 'ng-inline-svg';
import { NextGameCountdownComponent } from './components/next-game-countdown/next-game-countdown.component';
import { GameCardCountdownComponent } from './components/game-card-countdown/game-card-countdown.component';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { FGBMissingTranslationHandler, FGBTranslationLoader } from '@fgb/core';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [
    EventsComponent,
    GamesListWithTicketsComponent,
    EventsDropDownComponent,
    GameBannerComponent,
    GameCardComponent,
    GameListComponent,
    TicketTransferWindowStatusBarComponent,
    NextGameCountdownComponent,
    GameCardCountdownComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
    FormsModule,
    CarouselModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: FGBTranslationLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: FGBMissingTranslationHandler,
      },
    }),
  ],
  exports: [
    EventsComponent,
    GamesListWithTicketsComponent,
    EventsDropDownComponent,
    GameBannerComponent,
    GameListComponent,
    GameCardComponent,
    NextGameCountdownComponent,
    GameCardCountdownComponent,
  ],
})
export class EventsModule {}
