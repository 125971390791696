<fgb-banner [title]="'contact.us.page.title' | translate"></fgb-banner>

<div class="container">
  <div class="contact-us-box rounded p-3 border">
    <div class="col-12 col-md-8 offset-lg-2">
      <form [formGroup]="contactUsForm" validate (ngSubmit)="submit(contactUsForm.value, contactUsForm.valid)">
        <div class="contact-title-text mt-2 mb-1">
          {{ 'contact.us.category' | translate }}
        </div>
        <div>
          <div class="dropdown contact-us-dropdown-div">
            <select class="contact-us-dropdown w-100 text-left rounded p-2 border-0" formControlName="category">
              <option *ngFor="let category of categories$ | async" [value]="category.Value">
                {{ category.Value }}
              </option>
            </select>
          </div>
        </div>
        <div>
          <div class="contact-title-text mt-2 mb-1">
            {{ 'contact.us.message' | translate }}
          </div>
          <textarea class="input-group contact-us-input-group h-200 rounded" formControlName="message"></textarea>
          <div class="alert alert-danger">
            <ng-container *ngIf="submitted && f.message.errors && !sentSuccessfully">
              <div *ngIf="f.message.errors.required">
                {{ 'contact.us.message.error' | translate }}
              </div>
            </ng-container>
            <ng-container *ngIf="f.message.errors && !sentSuccessfully">
              <div *ngIf="f.message.errors.whitespace">
                {{ 'contact.us.message.whitespace' | translate }}
              </div>
              <div *ngIf="f.message.errors.invalidTags || f.message.errors.profanityFilter">
                {{ 'contact.us.message.invalid.data' | translate }}
              </div>
            </ng-container>
          </div>
        </div>
        <button
          class="contact-us-send-button d-block ml-auto mr-auto rounded mt-4 mb-2 border-0"
          type="submit"
          [disabled]="sentSuccessfully || !contactUsForm?.valid"
        >
          {{ 'contact.us.submit' | translate }}
        </button>
        <div class="success-message text-center" *ngIf="sentSuccessfully">
          {{ 'contact.us.submit.success' | translate }}
        </div>
      </form>
    </div>
  </div>
</div>
