<fgb-banner [title]="'terms.page.title' | translate "></fgb-banner>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="pt-4 term-box">

        <fgbcl-terms-and-conditions [type]="termsAndConditionsType"></fgbcl-terms-and-conditions>
      </div>
    </div>
  </div>
</div>
