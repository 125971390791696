import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@fgb/core';
import { ErrorStateService } from '@fgb/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  partnerDisabled: boolean = false;
  private _destroyed$ = new Subject();

  constructor(
    private router: Router,
    public errorService: ErrorStateService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    if (this.authService.hasCurrentRefreshToken()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.checkpartnerDisabledFlag();
  }

  checkpartnerDisabledFlag() {
    this.translate
      .stream('partner.disabled.flag')
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data: string) => {
        if (data === 'true') {
          this.partnerDisabled = JSON.parse(data);
        }
      });
  }
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
