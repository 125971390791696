import { Directive, Input, HostBinding, HostListener, OnInit } from '@angular/core';
import { config } from '@fgb/core';

/**
 * Enables a default image source to be specified that will be shown upon image retrieval error (i.e. HTTP 404).
 * Usage:
 *  <img src="https://www.normal-image-address.com/image.jpg" useDefault="true"></img>
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'img[useDefault]',
})
export class ImgDefaultDirective implements OnInit {
  @HostBinding('attr.src') @Input() src: string;
  @Input() useDefault: boolean;
  @Input() loyaltyType: 'marketplace' | 'auction' | 'lotto' | 'wallet' | 'walletRedeemed';
  @HostBinding('class.image-loaded') imageLoaded = false;
  @HostBinding('class.image-error') imageError = false;
  DefaultImgSrc: string;

  constructor() {}

  ngOnInit() {
    if (!this.loyaltyType) {
      this.DefaultImgSrc = `${config.contentUrl}/images/noimage.png`;
    } else {
      switch (this.loyaltyType) {
        case 'marketplace': {
          this.DefaultImgSrc = 'assets/images/placeholders/marketplace-item.png';
          break;
        }
        case 'auction': {
          this.DefaultImgSrc = 'assets/images/placeholders/auction-item.png';
          break;
        }
        case 'lotto': {
          this.DefaultImgSrc = 'assets/images/placeholders/lotto-item.png';
          break;
        }
        case 'wallet': {
          this.DefaultImgSrc = 'assets/images/placeholders/marketplace-item.png';
          break;
        }
        default: {
          this.DefaultImgSrc = 'assets/images/placeholders/marketplace-item.png';
          break;
        }
      }
    }
  }

  /**
   * Called on error. Sets the default image source if required and the class to 'image-error'.
   */
  @HostListener('error') updateUrl() {
    if (this.useDefault) {
      this.src = this.DefaultImgSrc;
    }
    this.imageError = true;
  }

  /**
   * Called on success. Leaves the requested image source as-is and sets the class to 'image-loaded'.
   */
  @HostListener('load') load() {
    this.imageLoaded = true;
  }
}
