import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import {
  StepType,
  RegistrationStepQuery,
  RegistrationStepService,
  LoggingService,
  ErrorStateService,
  MemberDetails,
  ProviderUiActionResponse,
  MemberDetailsRegistration,
  PaymentService,
  GiftCodeModel,
  GiftCodeType,
  GiftCodeEntitlementValueType,
  RegistrationPurchaseBasket,
  RegistrationBasketService,
  RegistrationBasketQuery,
  GiftCodeService,
  ProviderActionKind,
  MemberQuery,
  AddressQuery,
  AddressType,
  Address,
  RegistrationPurchaseBasketItem,
  CardTokenQuery,
  AuthService,
  AddressService,
} from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Regex } from 'src/app/shared/utilities/regex';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-registration-payment',
  templateUrl: './registration-payment.component.html',
  styleUrls: ['./registration-payment.component.scss'],
})
export class RegistrationPaymentComponent implements OnInit, OnDestroy {
  readonly stepType = StepType.Payment;
  basket$: Observable<RegistrationPurchaseBasket>;
  basketItems: RegistrationPurchaseBasketItem[] = [];
  providerResponse?: ProviderUiActionResponse;
  editingBillingAddress: boolean = false;
  editingShippingAddress: boolean = false;
  paymentInitialised: boolean = false;
  billingAddress: Address | undefined;
  shippingAddress: Address | undefined;
  billingAddressForm: FormGroup;
  shippingAddressForm: FormGroup;
  hasSubmittedBillingAddress: boolean = false;
  hasSubmittedShippingAddress: boolean = false;
  giftCodeInput: string;
  totalBasketPrice: number;
  totalItemPrice: number;
  totalTaxPrice: number;
  totalShippingPrice: number;
  appliedGiftCodes: GiftCodeModel[] = [];
  giftCodeInputMade = false;
  emptyBasket: RegistrationPurchaseBasket = {
    Tax: 0,
    TotalPrice: 0,
    TotalPriceWithTaxAndShipping: 0,
    TotalPriceWithTax: 0,
    TotalShipping: 0,
    TotalTax: 0,
    GiftCodes: [],
    BasketItems: [],
  };
  currentUser: MemberDetails | undefined;
  addresses: Address[] = [];
  memberDetailsSubscription: Subscription;

  cardTokenCount: number = 0;
  payWithCardToken: boolean = false;
  giftCodeAccepted: boolean = false;

  @ViewChild('unappliedGiftCodeModal', { static: true }) unappliedGiftCodeModalRef: ElementRef;

  constructor(
    private errorService: ErrorStateService,
    private registrationStepQuery: RegistrationStepQuery,
    private registrationStepService: RegistrationStepService,
    private registrationBasketService: RegistrationBasketService,
    private registrationBasketQuery: RegistrationBasketQuery,
    private logService: LoggingService,
    private paymentService: PaymentService,
    private giftCodeService: GiftCodeService,
    private formBuilder: FormBuilder,
    private memberQuery: MemberQuery,
    private addressQuery: AddressQuery,
    private addressService: AddressService,
    private cardTokenQuery: CardTokenQuery,
    private authService: AuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.logService.info('Retrieving latest basket information...');
    this.registrationBasketService.fetchRegistrationBasketForCurrentWizard().toPromise();
    this.basket$ = this.registrationBasketQuery.select().pipe(
      tap((basket) => {
        if (this.appliedGiftCodes.length < 1) {
          this.basketItems = basket.BasketItems;
          this.totalBasketPrice = basket.TotalPriceWithTaxAndShipping;
          this.totalItemPrice = basket.TotalPrice;
          this.totalShippingPrice = basket.TotalShipping;
          this.totalTaxPrice = basket.TotalTax;
        }
      })
    ) as Observable<RegistrationPurchaseBasket>;

    this.memberDetailsSubscription = this.memberQuery.selectMemberDetails().subscribe((md) => {
      this.currentUser = md;
      if (this.currentUser) {
        this.addressQuery
          .selectAddresses([AddressType.DefaultAddress])
          .pipe(
            tap((addresses) => {
              this.addresses = addresses;
            })
          )
          .toPromise();
      }
      this.billingAddress = this._getBillingAddress();
      this.shippingAddress = this._getShippingAddress();
    });

    if (this.authService.isAuthenticated()) {
      this.cardTokenQuery
        .selectCard()
        .pipe(tap((tokens) => (this.cardTokenCount = tokens.length)))
        .toPromise();
    }
  }

  ngOnDestroy() {
    if (this.memberDetailsSubscription) {
      this.memberDetailsSubscription.unsubscribe();
    }
  }

  submitStep() {
    if (this.giftCodeInputMade && !this.errorService.hasErrors()) {
      this.unappliedGiftCodeModal();
    } else {
      if (!this.paymentInitialised && this.billingAddress && this.shippingAddress) {
        this.paymentInitialised = true;
        this.logService.info('Retrieving provider action response for payment request...');
        const callbackUrl: string = document.getElementsByTagName('base')[0].href + '/register/payment/result';

        // Contact the API to get the provider action response
        this.registrationStepService
          .makeRegistrationPayment(
            this.billingAddress,
            this.shippingAddress,
            callbackUrl,
            this.appliedGiftCodes,
            this.payWithCardToken
          )
          .toPromise()
          .then((response) => {
            if (response.ProviderActionInformation.ActionKind == ProviderActionKind.None) {
              this.registrationStepService.submitRegistrationStepData(this.stepType, this.emptyBasket).toPromise();
            } else {
              this.paymentService.process(response, 'paymentdiv', 'register/payment');
            }
          })
          .catch((error) => {
            this.logService.error(error);
            this.errorService.addError('An error occurred, please try again.');
            this.paymentInitialised = false;
          });
      }
    }
  }

  /** Start editing the user's billing address. */
  editBillingAddress() {
    if (!this.billingAddress) {
      return;
    }
    this.editingBillingAddress = true;
    this.logService.debug('Opening billing address form with initial value', this.billingAddress);
    this.billingAddressForm = this.formBuilder.group({
      FirstName: [this.billingAddress.FirstName, Validators.required],
      Surname: [this.billingAddress.Surname, Validators.required],
      Street: [this.billingAddress.Street, Validators.required],
      Town: [this.billingAddress.Town, Validators.required],
      County: [this.billingAddress.County, Validators.required],
      Postcode: [this.billingAddress.Postcode, [Validators.required, Validators.pattern(Regex.postCode)]],
      Country: [this.billingAddress.Country, Validators.required],
    });
  }

  /** Start editing the user's shipping address. */
  editShippingAddress() {
    if (!this.shippingAddress) {
      return;
    }
    this.editingShippingAddress = true;
    this.logService.debug('Opening shipping address form with initial value', this.editingShippingAddress);
    this.shippingAddressForm = this.formBuilder.group({
      FirstName: [this.shippingAddress.FirstName, Validators.required],
      Surname: [this.shippingAddress.Surname, Validators.required],
      Street: [this.shippingAddress.Street, Validators.required],
      Town: [this.shippingAddress.Town, Validators.required],
      County: [this.shippingAddress.County, Validators.required],
      Postcode: [this.shippingAddress.Postcode, [Validators.required, Validators.pattern(Regex.postCode)]],
      Country: [this.shippingAddress.Country, Validators.required],
    });
  }

  /** Update the user's billing address with the value of the billingAddressForm. */
  async updateBillingAddress() {
    this.hasSubmittedBillingAddress = true;

    if (this.billingAddress && this.billingAddressForm.valid) {
      this.billingAddress = { ...this.billingAddress, ...this.billingAddressForm.value };

      this.editingBillingAddress = false;

      this.addressService.saveAddress(this.billingAddress!);
    }
  }

  async updateShippingAddress() {
    this.hasSubmittedShippingAddress = true;

    if (this.shippingAddress && this.shippingAddressForm.valid) {
      this.shippingAddress = { ...this.shippingAddress, ...this.shippingAddressForm.value };

      this.editingShippingAddress = false;
      this.addressService.saveAddress(this.shippingAddress!);
    }
  }

  /** Clear the billing address form fields. */
  clearAddressForm(addressForm: FormGroup) {
    addressForm.patchValue({
      FirstName: '',
      Surname: '',
      Street: '',
      Town: '',
      County: '',
      Postcode: '',
      Country: '',
    });
  }

  /** Gets the user's address from the personal details step data. */
  private _getDefaultBillingAddress(): Address | undefined {
    const personalDetailsStep = this.registrationStepQuery.getStep(StepType.PersonalDetails);
    if (personalDetailsStep) {
      const personalDetails = personalDetailsStep.Data as MemberDetailsRegistration;
      return {
        AddressName: 'Billing Address',
        FirstName: personalDetails.FirstName,
        Surname: personalDetails.Surname,
        Street: personalDetails.Street,
        Town: personalDetails.Town,
        County: personalDetails.County,
        Postcode: personalDetails.PostCode,
        Country: personalDetails.Country,
        HomeNumber: personalDetails.HomeNumber,
        EmailAddress: personalDetails.EmailAddress1,
        PortalId: personalDetails.PortalId,
        ClubId: personalDetails.ClubId,
      } as Address;
    }
    if (this.addresses) {
      let billingAddress1 = this.addresses.filter((a) => a.AddressType === AddressType.BillingAddress1)[0] as Address;
      if (billingAddress1) {
        return billingAddress1;
      }

      let billingAddress2 = this.addresses.filter((a) => a.AddressType === AddressType.BillingAddress2)[0] as Address;
      if (billingAddress2) {
        return billingAddress2;
      }
    }
    if (this.currentUser) {
      return {
        AddressName: 'Billing Address',
        FirstName: this.currentUser.FirstName,
        Surname: this.currentUser.Surname,
        Street: this.currentUser.Street,
        Town: this.currentUser.Town,
        County: this.currentUser.County,
        Postcode: this.currentUser.PostCode,
        Country: this.currentUser.Country,
        HomeNumber: this.currentUser.HomeNumber,
        EmailAddress: this.currentUser.EmailAddress1,
        PortalId: this.currentUser.PortalId,
        ClubId: this.currentUser.ClubId,
      } as Address;
    }
    return;
  }

  private _getDefaultShippingAddress(): Address | undefined {
    const personalDetailsStep = this.registrationStepQuery.getStep(StepType.PersonalDetails);
    if (personalDetailsStep) {
      const personalDetails = personalDetailsStep.Data as MemberDetailsRegistration;
      return {
        AddressName: 'Billing Address',
        FirstName: personalDetails.FirstName,
        Surname: personalDetails.Surname,
        Street: personalDetails.Street,
        Town: personalDetails.Town,
        County: personalDetails.County,
        Postcode: personalDetails.PostCode,
        Country: personalDetails.Country,
        HomeNumber: personalDetails.HomeNumber,
        EmailAddress: personalDetails.EmailAddress1,
        PortalId: personalDetails.PortalId,
        ClubId: personalDetails.ClubId,
      } as Address;
    }
    if (this.addresses) {
      let shippingAddress1 = this.addresses.filter((a) => a.AddressType === AddressType.ShippingAddress1)[0] as Address;
      if (shippingAddress1) {
        return shippingAddress1;
      }

      let shippingAddress2 = this.addresses.filter((a) => a.AddressType === AddressType.ShippingAddress2)[0] as Address;
      if (shippingAddress2) {
        return shippingAddress2;
      }
    }
    if (this.currentUser) {
      return {
        AddressName: 'Billing Address',
        FirstName: this.currentUser.FirstName,
        Surname: this.currentUser.Surname,
        Street: this.currentUser.Street,
        Town: this.currentUser.Town,
        County: this.currentUser.County,
        Postcode: this.currentUser.PostCode,
        Country: this.currentUser.Country,
        HomeNumber: this.currentUser.HomeNumber,
        EmailAddress: this.currentUser.EmailAddress1,
        PortalId: this.currentUser.PortalId,
        ClubId: this.currentUser.ClubId,
      } as Address;
    }
    return;
  }

  /** Gets the user's billing address from the session storage, or the personal details step if not found. */
  private _getBillingAddress(): Address | undefined {
    try {
      const billingAddressFromSession = sessionStorage.getItem('BillingAddress');
      if (billingAddressFromSession) {
        this.logService.info('Using billing address from session storage');
        return JSON.parse(billingAddressFromSession);
      }
    } catch {
      this.logService.error('Failed to retrieve billing address from session storage, using default address instead...');
    }

    return this._getDefaultBillingAddress();
  }

  /** Gets the user's shipping address from the shipping address step data, or the personal details step if not provided. */
  private _getShippingAddress(): Address | undefined {
    const shippingAddressStep = this.registrationStepQuery.getStep(StepType.ShippingAddress);
    if (shippingAddressStep && shippingAddressStep.Data) {
      return shippingAddressStep.Data as Address;
    }

    return this._getDefaultShippingAddress();
  }

  /** Takes in a gift code string, checks to see if the code is valid and returns the calculated discount */
  applyGiftCode() {
    this.errorService.clearErrors();
    this.giftCodeAccepted = false;

    if (!this.giftCodeInput) {
      this.errorService.addError('Gift Code cannot be empty');
      return;
    }

    if (!!this.appliedGiftCodes.filter((code) => code.Code === this.giftCodeInput).length) {
      this.errorService.addError('This gift code has already been applied');
      return;
    }

    if (this.totalBasketPrice === 0) {
      this.errorService.addError('Gift code cannot be applied if the basket value is 0');
      return;
    }

    if (this.appliedGiftCodes.length > 2) {
      this.errorService.addError('The maximum amount of gift codes have been applied');
      return;
    }

    this.giftCodeService
      .validateGiftCode(this.giftCodeInput)
      .toPromise()
      .then((giftCode) => {
        if (
          giftCode.GiftCodeAssignType == GiftCodeType.Product &&
          !this.basketItems.filter(
            (item) =>
              item.MembershipOption.ProductId == giftCode.EntitlementItemId ||
              item.MembershipOption.ProductId == giftCode.EntitlementItemId2
          ).length
        ) {
          this.errorService.addError('Gift code does not apply to any of the basket items.');
          return;
        } else if (
          giftCode.EntitlementValueType == GiftCodeEntitlementValueType.Percentage &&
          !!this.appliedGiftCodes.filter((gc) => gc.EntitlementValueType == GiftCodeEntitlementValueType.Percentage).length
        ) {
          this.errorService.addError('Only one percentage discount gift code can applied.');
          return;
        } else if (giftCode.GiftCodeStatus != 0) {
          this.errorService.addError('Gift code status not avalaible.');
          return;
        } else {
          this.giftCodeAccepted = true;
          this.giftCodeInput = '';
          this.giftCodeInputMade = false;
        }

        if (this.appliedGiftCodes.length > 0) {
          /** reset basket values and recalculate with added giftcodes */
          this.resetBasketValues();
        }

        if (giftCode.EntitlementValueType === GiftCodeEntitlementValueType.Value) {
          this.appliedGiftCodes.unshift(giftCode);
        } else {
          this.appliedGiftCodes.push(giftCode);
        }

        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });
      })
      .catch((err) => console.log(err));
  }

  /** Calculate the new total basket price after Gift Code has been applied */
  private _calculateGiftCodeDeduction(giftCode: GiftCodeModel) {
    this.errorService.clearErrors();
    let discount = 0;

    if (giftCode.GiftCodeAssignType === GiftCodeType.Order) {
      switch (giftCode.EntitlementValueType) {
        // Value
        case GiftCodeEntitlementValueType.Value: {
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalBasketPrice;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code === giftCode.Code);
          discount = this.totalBasketPrice;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }
    } else if (giftCode.GiftCodeAssignType === GiftCodeType.Shipping) {
      if (this.totalShippingPrice === 0) {
        this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x.Code !== giftCode.Code);
        this.errorService.addError('Gift Code cannot be applied if the shipping is already 0');
        return;
      }

      switch (giftCode.EntitlementValueType) {
        case GiftCodeEntitlementValueType.Value: {
          // Value
          discount = giftCode.EntitlementValue;
          break;
        }
        case GiftCodeEntitlementValueType.Percentage: {
          // Percentage
          discount = (giftCode.EntitlementValue / 100) * this.totalShippingPrice;
          break;
        }
        case GiftCodeEntitlementValueType.NumberOfEntitlements: {
          // Number of Items - Only used for Free
          discount = this.totalShippingPrice;
          break;
        }
        default: {
          this.errorService.addError('Invalid Gift Code');
          break;
        }
      }

      this.totalShippingPrice = Math.max(0, this.totalShippingPrice - discount);
    } else if (giftCode.GiftCodeAssignType == GiftCodeType.Product) {
      var numberOfTimesApplied = 0;
      this.basketItems
        .filter(
          (item) =>
            item.MembershipOption.ProductId == giftCode.EntitlementItemId ||
            item.MembershipOption.ProductId == giftCode.EntitlementItemId2
        )
        .forEach((item) => {
          if (numberOfTimesApplied < giftCode.EntitlementValue) {
            numberOfTimesApplied++;
            discount += item.PriceWithTaxAndShipping;
          }
        });

      this.totalItemPrice = Math.max(0, this.totalItemPrice - discount);
    }
    this.totalBasketPrice = Math.max(0, this.totalBasketPrice - discount);
  }

  resetBasketValues() {
    this.registrationBasketQuery
      .select()
      .pipe(
        tap((basket) => {
          this.totalBasketPrice = basket.TotalPriceWithTaxAndShipping;
          this.totalItemPrice = basket.TotalPrice;
          this.totalShippingPrice = basket.TotalShipping;
          this.totalTaxPrice = basket.TotalTax;
        })
      )
      .toPromise();
  }

  /** Works out the total basket price after removal of gift code */
  removeGiftCode(code: string) {
    this.errorService.clearErrors();
    let giftCode = this.appliedGiftCodes.find((x) => x.Code === code);

    if (giftCode) {
      this.appliedGiftCodes = this.appliedGiftCodes.filter((x) => x !== giftCode);

      /** reset basket values and recalculate with remaining giftcodes */
      this.resetBasketValues();

      if (this.appliedGiftCodes.length > 0) {
        this.appliedGiftCodes.forEach((x) => {
          this._calculateGiftCodeDeduction(x);
        });
      }
    }
  }

  detectGiftCodeInput(input: string) {
    this.giftCodeInputMade = false;
    if (/\S/.test(input)) {
      this.errorService.clearErrors();
      this.giftCodeInputMade = true;
    }
  }

  unappliedGiftCodeModal() {
    this.modalService.open(this.unappliedGiftCodeModalRef, { centered: true });
  }

  removeUnappliedGiftCode() {
    this.giftCodeInput = '';
  }
}
