<div class="d-none d-lg-block">
  <fgb-banner [isHomePage]="true"></fgb-banner>
</div>
<div class="container text-center">
  <!-- Cards -->
  <div class="row mt-3">
    <div class="col-lg-6 order-1">
      <fgb-member-card></fgb-member-card>
    </div>

    <div class="col-lg-6 mt-3 mt-lg-0 order-lg-2 order-3 mb-3">
      <a href="{{ 'ticketing.account.link.url' | translate }}" target="_blank">
        <div class="next-game-bg card shadow border-0 justify-content-center">
          <div class="game overflow-hidden rounded m-2 d-flex flex-column align-items-center justify-content-center">
            <img src="assets/images/logo.svg" class="mr-2 ticket-account-logo" />
            <h2 class="card-title text-white">{{ 'ticketing.account.title' | translate }}</h2>
          </div>
        </div>
      </a>
    </div>
    <div class="col-lg-4 mt-3 mt-lg-0 order-lg-3 order-2 d-none">
      <ng-container *notInProductCode="productCode.Partners">
        <a [routerLink]="['/earn']">
          <div class="card earn-more text-white d-lg-block d-none flex-row justify-content-around align-items-center py-3">
            <div><u>EARN MORE</u></div>
            <div><u>UNLOCK MORE</u></div>
            <div><u>WIN MORE</u></div>
          </div>
          <div
            class="
              card
              earn-more
              text-white
              d-lg-none d-flex
              flex-row
              justify-content-center
              align-items-center
              border-bottom
              py-3
            "
            class="
              card
              earn-more
              text-white
              d-lg-none d-flex
              flex-row
              justify-content-center
              align-items-center
              border-bottom
              py-3
            "
          >
            <div class="pr-2 border-bottom">EARN MORE</div>
            <div class="pr-2 border-bottom">UNLOCK MORE</div>
            <div class="border-bottom">WIN MORE</div>
          </div>
        </a>
      </ng-container>
      <ng-container *inProductCode="productCode.Partners">
        <a [routerLink]="['/rewards/events']">
          <div class="card see-all text-white d-flex justify-content-center align-items-center">
            <h1>
              {{ 'home.see.all.rewards' | translate }}
            </h1>
          </div>
        </a>
      </ng-container>
    </div>
  </div>

  <!-- Voucher Box -->
  <div class="mb-3" *notInProductCode="[productCode.Trial_Member, productCode.NoPartners]">
    <fgb-vouchers></fgb-vouchers>
  </div>

  <!-- Feature challenges -->
  <ng-container *notInProductCode="productCode.Trial_Member">
    <div class="mt-4">
      <fgbcl-scorecard-carousel
        [icon]="'chevron_right'"
        [showAllView]="true"
        [version2Design]="true"
        [phaseThree]="true"
      ></fgbcl-scorecard-carousel>
    </div>
  </ng-container>

  <!-- Feature rewards -->
  <ng-container *notInProductCode="productCode.Trial_Member">
    <div class="mt-2">
      <fgbcl-featured-rewards [showAllPath]="'/rewards/events'"></fgbcl-featured-rewards>
    </div>
  </ng-container>

  <!-- Voucher Box -->
  <div class="mt-lg-5 mt-3" *notInProductCode="[productCode.Trial_Member, productCode.Partners]">
    <fgb-vouchers></fgb-vouchers>
  </div>
</div>

<div class="container text-center">
  <!-- Campaign Offers -->
  <ng-container *notInProductCode="productCode.Trial_Member">
    <fgbcl-campaigns-offers-list></fgbcl-campaigns-offers-list>
  </ng-container>

  <!-- Announcements -->
  <div class="row">
    <div class="col-12">
      <fgbcl-announcements></fgbcl-announcements>
    </div>
  </div>
</div>
