<ul class="nav nav-secondary mixed-loyalty-nav my-0">
  <a
    class="nav-item"
    [routerLink]="['/rewards/events']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="ProcessProviderId.VirtualEvent"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.events' | translate }}</label>
  </a>
  <a
    class="nav-item"
    [routerLink]="['/rewards/marketplace']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'marketplace'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.marketplace' | translate }}</label>
  </a>
  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.auctions'">
    <a
      class="nav-item btn"
      [routerLink]="['/rewards/auctions']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
      attr.aria-label="{{ 'rewards.nav.auctions.screenreader' | translate: { fgbdefault: 'Move to Auctions page' } }}"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'auction'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.auctions' | translate }}</label>
    </a>
  </ng-container>
  <a
    class="nav-item"
    [routerLink]="['/rewards/raffles']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="'lotto'"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.lotto' | translate }}</label>
  </a>

  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.shipping'">
    <a
      class="nav-item"
      [routerLink]="['/rewards/shipping']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="icon material-icons"> local_shipping </span>
      <label>{{ 'rewards.nav.shipping' | translate }}</label>
    </a>
  </ng-container>

  <ng-container *ifScreenSize="screenType.Desktop">
    <a
      class="nav-item"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.wallet' | translate }}</label>
    </a>
  </ng-container>
</ul>
