<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <img class="home-logo" src="assets/images/logo.svg" draggable="false" />
    </a>
    <ng-container *notInProductCode="productCode.Trial_Member">
      <a class="nav-item" [routerLink]="['/account']">
        <span class="icon material-icons material-mobile-nav">person</span>
      </a>
      <a class="nav-item" [routerLink]="['/rewards/events']">
        <span class="icon material-icons material-mobile-nav">redeem</span>
      </a>
      <a class="nav-item" [routerLink]="['/rewards/wallet']">
        <span class="icon material-icons material-mobile-nav">account_balance_wallet</span>
      </a>
    </ng-container>
    <button class="nav-item font-weight-bold navbar-toggler m-0 px-2" aria-label="Menu" (click)="isCollapsed = !isCollapsed">
      <span class="icon material-icons material-mobile-nav">menu</span>
    </button>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column p-1 mobile-nav-menu-content">
      <!-- Close Button -->
      <button class="close-button text-right border-0" aria-label="Close Modal" (click)="isCollapsed = !isCollapsed">
        <span class="icon font-weight-bold icon-cancel-circle"></span>
      </button>
      <div class="container">
        <a class="nav-item font-weight-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
          <img class="mobile-menu-logo" src="assets/images/logo_banner.png" draggable="false" />
        </a>
      </div>
      <div class="container">
        <ng-container *ngIf="memberCard$ | async as memberCard">
          <div class="member-details text-white">
            <div class="d-flex flex-column h-100">
              <div class="d-flex justify-content-between">
                <!--Name and ID-->
                <div class="card-id text-left w-100">
                  <div class="mb-2 card-name w-80" *ngIf="memberDetails$ | async as memberDetails">
                    {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
                  </div>
                  <div class="d-flex font-size-12">
                    <div>{{ memberCard.ProductCode }}</div>
                    <div class="px-1">-</div>
                    <div>{{ memberCard.ExternalRefNumber }}</div>
                  </div>
                </div>
              </div>

              <hr class="bg-white" />
              <!--Balances-->
              <ng-container *notInProductCode="productCode.Trial_Member">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-between align-items-end mb-1">
                    <div class="card-account-no points text-left border-white" *ngIf="pointsBalance$">
                      <div class="font-size-14 font-weight-bold mb-2">{{ pointsBalance$ | async | number }}</div>
                      <div class="font-size-12">{{ 'member.card.points.balance' | translate }}</div>
                    </div>
                  </div>
                </div>
                <hr class="bg-white" />
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container container">
        <ng-container *notInProductCode="productCode.Trial_Member">
          <a
            class="nav-item font-weight-bold large-item"
            [routerLink]="['/account']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">person</span>

              <div class="nav-text font-weight-bold">{{ 'nav.account' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/rewards/wallet']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">account_balance_wallet</span>

              <div class="nav-text font-weight-bold">{{ 'nav.wallet' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/rewards/events']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">redeem</span>

              <div class="nav-text font-weight-bold">{{ 'nav.rewards' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/badges']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">local_police</span>

              <div class="nav-text font-weight-bold">{{ 'nav.challenges' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <a
            class="nav-item"
            [routerLink]="['/earn']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
            *notInProductCode="productCode.Partners"
          >
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">emoji_events</span>
              <div class="nav-text font-weight-bold">{{ 'nav.earn' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.partners'">
            <ng-container *inProductCode="productCode.NoPartners">
              <ng-container *notInProductCode="productCode.ExcludedPartners">
                <a
                  class="nav-item"
                  [routerLink]="['/partners']"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <div class="left-side">
                    <div class="icon material-icons mx-3">people</div>
                    <div class="nav-text font-weight-bold">{{ 'account.nav.partners' | translate }}</div>
                  </div>
                  <div class="right-side">
                    <div class="material-icons icon">navigate_next</div>
                  </div>
                </a>
              </ng-container>
            </ng-container>
          </ng-container>

          <a
            class="nav-item"
            [routerLink]="['/contact']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">email</span>
              <div class="nav-text font-weight-bold">{{ 'nav.contact.us' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">article</span>
              <div class="nav-text font-weight-bold">{{ 'nav.terms' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
          <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
            <div class="left-side">
              <span class="icon material-icons material-mobile-dropdown mx-3">help</span>
              <div class="nav-text font-weight-bold">{{ 'nav.faq' | translate }}</div>
            </div>
            <div class="right-side">
              <div class="material-icons icon">navigate_next</div>
            </div>
          </a>
        </ng-container>
        <a class="nav-item" (click)="logout()">
          <div class="left-side">
            <span class="icon material-icons material-mobile-dropdown mx-3">power_settings_new</span>
            <div class="nav-text font-weight-bold">{{ 'nav.log.out' | translate }}</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
