<div class="d-lg-block d-none">
  <fgb-banner [title]="'auction.page.title' | translate"></fgb-banner>
</div>
<div class="d-lg-none d-block container mt-3">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<!-- Auction Image -->
<div class="container mt-3 px-lg-0">
  <a href="https://orioles.auctions.mlb.com/" target="_blank">
    <div class="container">
      <ng-container *ifScreenSize="screenType.Desktop">
        <img src="assets/images/rewards/DesktopAuctionsBanner.jpg" class="w-100" />
      </ng-container>
    </div>
    <ng-container *ifScreenSize="screenType.Mobile">
      <img src="assets/images/rewards/MobileAuctionsBanner.jpg" class="w-100" />
    </ng-container>
  </a>
</div>

<div class="container mt-3">
  <fgbcl-auction-list></fgbcl-auction-list>
</div>
