import { Component, OnInit } from '@angular/core';
import { TMAuthService } from '@fgb/core';

@Component({
  selector: 'fgb-tm-auth-callback',
  templateUrl: './tm-auth-callback.component.html',
  styleUrls: ['./tm-auth-callback.component.scss']
})
export class TmAuthCallbackComponent implements OnInit {
  
  constructor(private tmAuthservice: TMAuthService) { }
  
  ngOnInit() {
    let code = this.getParameter('code');
    let state = this.getParameter('state');
    if (code != ''){
      this.tmAuthservice.authCallback(code, state);
    }
  }

  getParameter(name: string) : string {
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if(!results){
      return '';
    }
    return results[1] as (string) || '';
  }
}
