<div class="banner">
  <h1>Guardian</h1>
</div>

<div class="container mt-4">
  <ng-container *ngIf="isGuardian; else notGuardian">
    <ng-container *ngIf="children$ | async as children">
      <div>
        <fgb-child-list></fgb-child-list>
      </div>
      <div class="rounded shadow overflow-hidden guardian-info">
        <div class="row">
          <div class="col-lg-6 col-12">
            <img class="w-100 h-100" src="assets/images/background.jpg" />
          </div>
          <div class="col-lg-6 col-12">
            <div class="mt-3 pr-lg-2 pl-lg-0 px-4">
              <h3 class="font-size-14">The official Kids club of Fortress!</h3>
              <p class="font-size-14 mt-4">
                The Fortress Kids Club, is the best way to show your Fortress pride! Sign up today and enjoy exclusive kids-only
                offers and unique experiences.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #notGuardian>
    <div class="text-left my-2">
      <div class="shadow rounded overflow-hidden guardian-info text-left my-2">
        <div class="row">
          <div class="col-lg-6 col-12">
            <img class="w-100 h-100" src="assets/images/background.jpg" />
          </div>
          <div class="col-lg-6 col-12">
            <div class="mt-3 pr-lg-2 pl-lg-0 px-4">
              <h3 class="font-size-14">The official Kids club of Fortress</h3>
              <p class="font-size-14 mt-4">
                The Fortress Kids Club, is the best way to show your Fortress pride! Sign up today and enjoy exclusive kids-only
                offers and unique experiences.
              </p>
              <button
                (click)="becomeGuardian()"
                class="btn btn-primary btn-guardian font-size-14 text-center text-white mx-auto mt-4 mt-lg-5 mb-4 p-0 w-100 font-weight-bold"
              >
                Click Here to Register as a Guardian
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center my-2">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="membership-panel rounded overflow-hidden shadow mt-lg-4 mt-3">
            <div class="membership-body text-white pt-3 pb-3 px-2">
              <h2 class="mb-4">Free</h2>
              <h4>Basic Membership</h4>
              <div class="mt-3 text-uppercase font-size-16 font-weight-bold">available for all fans ages 12 and under!</div>
            </div>
            <div class="membership-dropdown">
              <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false">
                <!--Q1-->
                <ngb-panel>
                  <ng-template ngbPanelHeader>
                    <div class="row">
                      <div class="col-10 membership-include-text font-weight-bold text-primary text-left">
                        Basic Membership Benefits Include:
                      </div>
                      <div class="col-1 offset-1 px-0">
                        <button class="btn" type="button" aria-label="Toggle dropdown" ngbPanelToggle>
                          <div>
                            <div class="dropdown-icon kid-dropdown svg" [inlineSVG]="'assets/images/icons/down-icon.svg'"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row">
                      <div class="content font-size-12 col-12">
                        <div class="text-left">
                          - Membership card and lanyard<br />
                          - Play games in the Fortress Portal<br />
                          - Exclusive email offers<br />

                          *Offers subject to change
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="membership-panel rounded overflow-hidden shadow mt-lg-4 mt-3">
            <div class="membership-body mvp text-white pt-3 pb-3 px-2">
              <h2 class="mb-4">$20</h2>
              <h4>King membership</h4>
              <div class="mt-3 text-uppercase font-size-16 font-weight-bold">available to all fans ages 4-12!</div>
            </div>
            <div class="membership-dropdown">
              <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false">
                <!--Q1-->
                <ngb-panel>
                  <ng-template ngbPanelHeader>
                    <div class="row">
                      <div class="col-10 membership-include-text font-weight-bold text-primary text-left">
                        King Membership Benefits Include:
                      </div>
                      <div class="col-1 offset-1 px-0">
                        <button class="btn" type="button" aria-label="Toggle dropdown" ngbPanelToggle>
                          <div>
                            <div class="dropdown-icon kid-dropdown svg" [inlineSVG]="'assets/images/icons/down-icon.svg'"></div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row">
                      <div class="content font-size-12 col-12">
                        <div class="text-left">
                          - Membership card and lanyard<br />
                          - Play games in the Fortress Portal<br />
                          - Exclusive email offers<br />
                          - 2020 Fortress yearbook<br />

                          *Offers subject to change
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
