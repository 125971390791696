<div class="d-none d-lg-block">
  <fgb-banner [title]="'account.activity.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3"><fgb-member-card></fgb-member-card></div>
<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="points-history">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.loyalty]" [accountView]="true"></fgb-transaction-history>
  </div>
</div>
