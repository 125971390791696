<div class="d-lg-block d-none">
  <fgb-banner [title]="'marketplace.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-3">
  <fgbcl-marketplace-list
    [isList]="true"
    [marketplaceItemView]="true"
    [marketplaceItemTypes]="[0, 1, 2]"
    [showSortBar]="true"
  ></fgbcl-marketplace-list>
</div>
