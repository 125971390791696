<h5 class="mt-4 mb-3 title-partner">{{ 'partner.list.title' | translate }}</h5>

<div class="card card-primary my-2 bg-white font-size-14">
  <div class="card-body pt-2">
    <div class="row text-left details-header">
      <div class="col-10 col-lg-3 d-lg-none d-block">
        <label class="font-weight-bold">{{ 'partner.details' | translate }}</label>
      </div>
      <div class="col-3 d-lg-block d-none">
        <label class="font-weight-bold">{{ 'partner.fullname' | translate }}</label>
      </div>
      <div class="col-5 d-lg-block d-none">
        <label class="font-weight-bold">{{ 'partner.email' | translate }}</label>
      </div>
      <div class="col-3 d-lg-block d-none"></div>
      <div class="col-1">
        <label class="font-weight-bold">{{ 'partner.action' | translate }}</label>
      </div>
    </div>
    <div *ngFor="let partner of approvedPartners$ | async" class="row text-left align-items-center my-lg-0 py-3 my-3">
      <div class="col-lg-3 col-10 partner-info">
        <div class="pb-lg-0 pb-1">{{ partner.FullName }}</div>
        <div class="d-flex d-lg-none flex-column">
          <div class="text-primary pb-lg-0 pb-1">{{ partner.Email }}</div>
          <div class="text-success font-weight-bold">Partner</div>
        </div>
      </div>
      <div class="col-5 d-lg-block d-none partner-info">
        <div class="text-primary">{{ partner.Email }}</div>
      </div>
      <div class="col-3 d-lg-block d-none partner-info">
        <div class="text-success font-weight-bold">Partner</div>
      </div>
    </div>

    <div *ngFor="let partner of pendingPartners$ | async" class="row text-left align-items-center my-lg-0 py-2 my-3">
      <div class="col-lg-3 col-10 partner-info">
        <div class="pb-lg-0 pb-1">{{ partner.FullName }}</div>
        <div class="d-flex d-lg-none flex-column">
          <div class="text-grey pb-lg-0 pb-1">{{ partner.Email }}</div>
          <div class="text-grey font-weight-bold">Pending</div>
        </div>
      </div>
      <div class="col-5 d-lg-block d-none partner-info">
        <div class="text-grey">{{ partner.Email }}</div>
      </div>
      <div class="col-3 d-lg-block d-none partner-info">
        <div class="text-grey font-weight-bold">Pending</div>
      </div>
      <div class="col-1">
        <button
          class="btn text-grey"
          confirmModal
          [confirmContent]="confirmContent"
          (confirm)="deletePending(partner.TicketKey)"
          (click)="selectPartner(partner.FullName, partner.Email)"
          aria-label="Delete partner"
        >
          <span class="icon material-icons"> delete_forever</span>
        </button>
      </div>
      <ng-template #confirmContent>
        <div class="row w-100 mx-0">
          <div class="d-flex align-items-center justify-content-center w-100">
            <span class="icon material-icons mr-2"> delete </span>
            <h5 class="text-center mb-0 font-size-18">{{ 'partner.list.modal.delete.title' | translate }}</h5>
          </div>
          <hr class="partner-divider w-100" />
          <div class="d-flex justify-content-center w-100 text-center">
            {{ 'partner.list.modal.delete.message' | translate }}
          </div>
          <div class="d-flex flex-column align-items-center w-100 text-center mt-4">
            <span>{{ partnerName }}</span>
            <span class="text-primary">{{ partnerEmail }}</span>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-container
      *ngIf="{
        approvedPartners: approvedPartners$ | async,
        pendingPartners: pendingPartners$ | async
      } as pt"
    >
      <ng-container *ngIf="!pt.approvedPartners.length && !pt.pendingPartners.length">
        <div class="text-center pt-3 font-size-14">{{ 'no.partner.placeholder' | translate }}</div>
      </ng-container>
    </ng-container>
  </div>
</div>
