<div class="point-transfer-success-page">
  <div class="card border">
    <div class="points-transfer-title">{{ 'point.transfer.success.title' | translate }}</div>
    <div class="line"></div>
    <div class="points-transfer-messages">
      {{ 'point.transfer.success.message' | translate }}
    </div>
    <div class="points-transfer-info">
      <div class="partner-details mb-2">
        <span class="font-size-12">
          <b class="mr-2">{{ 'point.transfer.person.detail' | translate }}:</b>
          {{ partner?.FullName }} ({{ partner?.Email }})
        </span>
      </div>
      <div class="points-value mb-5">
        <span>
          <b class="mr-2">{{ 'point.transfer.point.value' | translate }}</b> {{ amount }}
        </span>
      </div>
    </div>
    <div class="wrap-btn">
      <button class="btn btn-back-to-points-transfer" (click)="backToPointsTransfer()">
        {{ 'back.to.points.transfer' | translate }}
      </button>
    </div>
  </div>
</div>
