import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AnnouncementContentComponent } from './components/announcement-content/announcement-content.component';
import { SurveyItemComponent } from './components/survey/survey-item/survey-item.component';
import { SurveyItemAnswerComponent } from './components/survey/survey-item-answer/survey-item-answer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { SurveyItemQuizComponent } from './components/survey/survey-item/survey-item-quiz/survey-item-quiz.component';
import { SurveyItemPredictorComponent } from './components/survey/survey-item/survey-item-predictor/survey-item-predictor.component';
import { SurveyItemPollComponent } from './components/survey/survey-item/survey-item-poll/survey-item-poll.component';
import { SurveyCompletedPollComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll.component';
import { SurveyCompletedPollItemComponent } from './components/survey/survey-item-answer/completed/survey-completed-poll/survey-completed-poll-item/survey-completed-poll-item.component';
import { RouterModule } from '@angular/router';
import { JokesCardComponent } from './components/jokes-card/jokes-card.component';
import { NotificationHubComponent } from './components/notification/notification-hub.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ScorePredictorItemComponent } from './components/score-predictor/score-predictor-item/score-predictor-item.component';
import { ScorePredictorCompletedComponent } from './components/score-predictor/score-predictor-completed/score-predictor-completed.component';
import { ScorePredictorCompletedPendingComponent } from './components/score-predictor/score-predictor-completed/score-predictor-completed-pending/score-predictor-completed-pending.component';
import { ScorePredictorCompletedProcessedComponent } from './components/score-predictor/score-predictor-completed/score-predictor-completed-processed/score-predictor-completed-processed.component';
import { ScorePredictorRulesComponent } from './components/score-predictor/score-predictor-rules/score-predictor-rules.component';
import { ScorePredictorHeaderComponent } from './components/score-predictor/score-predictor-item/score-predictor-header/score-predictor-header.component';
import { ScorePredictorBodyComponent } from './components/score-predictor/score-predictor-item/score-predictor-body/score-predictor-body.component';
import { ScorePredictorFooterComponent } from './components/score-predictor/score-predictor-item/score-predictor-footer/score-predictor-footer.component';
import { ScorePredictorListComponent } from './components/score-predictor/score-predictor-list/score-predictor-list.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ShortLongDescriptionModule } from '@fgb/portal-component-library/src/lib/shared/short-long-description';
import { ErrorsModule } from '@fgb/portal-component-library/src/lib/shared/errors';
import { FilterByModule } from '@fgb/portal-component-library/src/lib/shared/filter-by';
import { NotificationModule } from '@fgb/portal-component-library/src/lib/club/notification';
import { NotificationButtonComponent } from './components/notification/notification-button/notification-button.component';
@NgModule({
  declarations: [
    AnnouncementsComponent,
    AnnouncementContentComponent,
    SurveyItemComponent,
    SurveyItemAnswerComponent,
    SurveyCompletedPollComponent,
    SurveyCompletedPollItemComponent,
    SurveyItemQuizComponent,
    SurveyItemPredictorComponent,
    SurveyItemPollComponent,
    JokesCardComponent,
    NotificationHubComponent,
    ScorePredictorItemComponent,
    ScorePredictorCompletedComponent,
    ScorePredictorCompletedPendingComponent,
    ScorePredictorCompletedProcessedComponent,
    ScorePredictorRulesComponent,
    ScorePredictorHeaderComponent,
    ScorePredictorBodyComponent,
    ScorePredictorFooterComponent,
    ScorePredictorListComponent,
    NotificationButtonComponent,
  ],
  imports: [
    NgbModule,
    SharedModule,
    CommonModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ShortLongDescriptionModule,
    ErrorsModule,
    FilterByModule,
    NotificationModule,
    ToastrModule.forRoot(),
    InlineSVGModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    RouterModule,
  ],
  exports: [
    AnnouncementsComponent,
    AnnouncementContentComponent,
    SurveyItemComponent,
    JokesCardComponent,
    NotificationHubComponent,
    ScorePredictorItemComponent,
    ScorePredictorCompletedComponent,
    ScorePredictorCompletedPendingComponent,
    ScorePredictorCompletedProcessedComponent,
    ScorePredictorRulesComponent,
    ScorePredictorHeaderComponent,
    ScorePredictorBodyComponent,
    ScorePredictorFooterComponent,
    ScorePredictorListComponent,
    NotificationButtonComponent,
  ],
  entryComponents: [],
})
export class ClubModule {}
