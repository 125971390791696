<ng-container *ngIf="!auctionBid.RedeemStatus">
  <div class="card redeem-card border-0 rounded py-4 px-3 bg-white">
    <!-- <div class="d-block d-lg-none">
      <div class="qr d-flex justify-content-center mx-auto mb-2">
        <qrcode [qrdata]="auctionBid.Barcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
      </div>
    </div> -->
    <div class="font-size-12 font-weight-bold text-center">{{ auctionBid.ItemName }}</div>
    <hr class="mt-4 mx-2 mb-3" />
    <div class="row">
      <div class="col-3 d-flex justify-content-left align-items-center">
        <img
          class="purchase-list-image shadow rounded"
          src="{{ auctionBid.ImageUrl | contentImage: '7' }}"
          alt="{{ auctionBid.ImageUrl }}"
          [useDefault]="true"
          [loyaltyType]="'marketplace'"
          draggable="false"
        />
      </div>
      <div class="col-9 pl-1">
        <div class="row">
          <!--Last Bid-->
          <div class="col-5 d-flex align-items-center pb-3">
            <span class="material-icons redeem-list-item-icons text-grey mr-1">emoji_events</span>
            <span class="font-size-12">{{ auctionBid.LastBid | FortressCurrencyPipe: 'PTS' }}</span>
          </div>
          <!--Auction Won-->
          <div class="col-7 d-flex align-items-center pb-3">
            <span class="material-icons redeem-list-item-icons text-grey mr-1">gavel</span>
            <span class="font-size-12">{{ auctionBid.EndDate | date: 'MMM d, yyyy' }}</span>
          </div>
        </div>
      </div>
    </div>
    <hr class="mx-2 my-3" />
    <div>
      <label class="text-secondary">{{ 'wallet.description' | translate }}</label>
      <fgbcl-short-long-description [description]="auctionBid.ItemDescription"></fgbcl-short-long-description>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="auctionBid.RedeemStatus">
  <div class="wallet-container">
    <ngb-accordion #a="ngbAccordion" [destroyOnHide]="false" class="w-100">
      <ngb-panel id="ngb-panel-i">
        <ng-template ngbPanelHeader let-opened="opened">
          <div class="row">
            <div class="col-9" (click)="a.toggle('ngb-panel-i')">
              <div class="row">
                <div class="col-5 d-flex justify-content-center align-items-center">
                  <img
                    class="purchase-list-image shadow rounded"
                    src="{{ auctionBid.ImageUrl | contentImage: '7' }}"
                    alt=""
                    [useDefault]="true"
                    [loyaltyType]="'auction'"
                    draggable="false"
                  />
                </div>
                <div class="col-7 py-3 px-0 text-black">
                  <div class="font-size-12 font-weight-bold text-left mb-1 purchase-title">{{ auctionBid.ItemName }}</div>
                  <div class="d-flex align-items-center">
                    <span class="material-icons redeem-list-item-icons text-grey mr-1">emoji_events</span>
                    <span class="font-size-12">{{ auctionBid.LastBid | FortressCurrencyPipe: 'PTS' }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-center align-items-center">
              <div>
                <button class="btn py-1 dropdown-tog" type="button" ngbPanelToggle>
                  <div>
                    <div
                      class="icon icon-arrow-1-down text-primary font-weight-bold font-size-14 d-flex align-items-center"
                    ></div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent class="content-dropdown">
          <div class="row">
            <div class="content col-12 pl-4 pr-4">
              <div class="font-size-12 font-weight-bold pt-3">Date Redeemed</div>
              <div class="mb-3">{{ auctionBid.EndDate | date: 'MMM d, yyyy' }}</div>
              <div class="pb-4 mb-2">
                <label class="text-primary">{{ 'wallet.description' | translate }}</label>
                <fgbcl-short-long-description [description]="auctionBid.ItemDescription"></fgbcl-short-long-description>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
</ng-container>
