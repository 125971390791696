<ng-container *ngIf="mobilePassUrl$ | async as mobilePass; else urlNotFound">
  <!--Desktop-->
  <ng-container *ngIf="deviceDetector.isDesktop()">
    <button class="btn btn-danger" (click)="downloadPassDesktop(mobilePass.url)">Download Mobile Pass</button>
  </ng-container>
  <!--Mobile-->
  <ng-container *ngIf="deviceDetector.isMobile()">
    <!--ANDROID-->
    <ng-container *ngIf="deviceDetector.os.toUpperCase() === 'ANDROID'">
      <a [href]="mobilePass.url" target="_blank">
        <button class="btn btn-android" aria-label="Download app on Android">
          <img src="assets/images/passes/google.svg" />
        </button>
      </a>
    </ng-container>
    <!--IOS-->
    <ng-container *ngIf="deviceDetector.os.toUpperCase() === 'IOS'">
      <a [href]="mobilePass.url" target="_blank">
        <button class="btn" aria-label="Download app on iOS">
          <img src="assets/images/passes/apple.svg" />
        </button>
      </a>
    </ng-container>
  </ng-container>
</ng-container>

<!--Loading pass-->
<ng-template #urlNotFound>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
