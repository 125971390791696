import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardService, AuthService, Guid, ErrorStateService } from '@fgb/core';

@Component({
  selector: 'fgb-mlbam-user-login',
  templateUrl: './mlbam-user-login.component.html',
  styleUrls: ['./mlbam-user-login.component.scss'],
})
export class MlbamUserLoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    public cardService: CardService,
    public errorService: ErrorStateService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {}
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService
      .mlbamLogin(this.f.username.value, this.f.password.value, new Guid().toString().split('-').join(''))
      .toPromise()
      .catch(() => {
        this.loading = false;
        this.errorService.clearErrors();
        this.errorService.addError('Invalid Email Address and Password.');
      });
  }
}
