<div class="d-none d-lg-block">
  <fgb-banner [title]="'partner.page.title' | translate"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3"><fgb-member-card></fgb-member-card></div>
<fgb-account-nav></fgb-account-nav>
<div *ngIf="partnerLimit$ | async as partnerLimit" class="container">
  <div class="mb-4 mt-2 mt-lg-0">
    <div class="m-0">
      <h5 class="mt-4 mb-2 title-partner">{{ 'partner.title' | translate }}</h5>
      <p
        class="m-0 pb-3 font-size-14 partner-description"
        [innerHTML]="'partner.page.info.text' | translate | markdownTranslate"
      ></p>
      <div class="m-0 flex-nowrap pr-0 pl-0">
        <div class="d-flex align-items-center flex-lg-row flex-column">
          <button
            class="partner-button border-0 text-center text-white bg-primary pt-0 d-flex align-items-center justify-content-center mr-lg-4 mb-3 mb-lg-0"
            (click)="openAddForm()"
            aria-label="Add new partner"
            [ngClass]="{
              active: !showAcceptForm,
              'not-active': showAcceptForm,
              'greyed-out': currentPartnerCount >= partnerLimit
            }"
          >
            <span class="material-icons mr-2"> person_add_alt_1 </span>
            <label class="cursor-pointer mb-0">Add New Partner</label>
          </button>
          <button
            class="partner-button border-0 text-center text-white bg-success pt-0 d-flex align-items-center justify-content-center"
            (click)="openAcceptForm()"
            [ngClass]="{ active: !showAddForm, 'not-active': showAddForm, 'greyed-out': currentPartnerCount >= partnerLimit }"
            aria-label="Accept partner"
          >
            <span class="material-icons mr-2"> task_alt </span>
            <label class="cursor-pointer mb-0">Accept Partner</label>
          </button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="showAddForm || showAcceptForm">
      <hr class="m-2" />
      <div class="card-body">
        <fgb-partner-add-form (close)="openAddForm()" *ngIf="showAddForm"></fgb-partner-add-form>
        <fgb-partner-accept-form (close)="openAcceptForm()" *ngIf="showAcceptForm"></fgb-partner-accept-form>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="currentPartnerCount > 0; else partnerPlaceholder">
    <fgb-partner-list></fgb-partner-list>
  </ng-container>
  <ng-template #partnerPlaceholder>
    <div class="col-12 col-lg-4 col-md-6 offset-md-3 offset-lg-4 no-partners">
      <div class="icon material-icons text-grey w-100 text-center">people</div>
      <div class="no-partner-text text-center mt-2">
        <h5 class="text-primary">{{ 'no.partner.placeholder.title' | translate }}</h5>
        <div class="text-info font-size-12">{{ 'no.partner.placeholder.description' | translate }}</div>
      </div>
    </div>
  </ng-template>
</div>
