<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="login-banner" draggable="false" src="assets/images/maintain-img.png" />
  <!--Right Side-->
  <div class="p-4 right-side-login text-center mx-auto position-relative">
    <img src="assets/images/login-registration/login-logo.png" />
    <div class="panel-text mlb-text font-size-14 text-left">
      {{ 'login.page.description' | translate }}
    </div>
    <fgb-mlbam-user-login></fgb-mlbam-user-login>
  </div>
</div>
