export class ProductCode {
  public static Orange = ['Orange', 'Orange Partner'];
  public static Black = ['Black', 'Black Partner'];
  public static Diamond = ['Diamond', 'Diamond Partner'];
  public static Rookie = ['Rookie', 'Rookie Partner'];
  public static Pro = ['Pro', 'Pro Partner'];
  public static Veteran = ['Veteran', 'Veteran Partner'];
  public static Gold_Glove = ['Gold Glove', 'Gold Glove Partner'];
  public static Trial_Member = ['Trial Member', 'Trial Membership'];

  public static ExcludedPartners = [ 'Rookie', 'Pro', 'Veteran', 'Gold Glove'];
  public static NoPartners = ['Orange', 'Black', 'Diamond', 'Rookie', 'Pro', 'Veteran', 'Gold Glove'];
  public static Partners = ['Black Partner', 'Orange Partner', 'Diamond Partner'];
}
