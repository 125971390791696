<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <img class="login-banner" draggable="false" src="assets/images/maintain-img.png" />
  <div class="p-4 right-side-login text-center">
    <h4 class="text-center text-lg-left text-secondary">SELECT TICKET ACCOUNT</h4>
    <ng-container>
      <fgbcl-errors></fgbcl-errors>

      <div *ngIf="this.availableAccounts.length > 0; else noAccounts" class="table-container mb-2">
        <div class="row px-2 my-3 mx-1 py-3 rounded shadow account-card text-left mb-5" *ngFor="let account of availableAccounts">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-6 col-5">
                <label>Name</label>
                <div class="info-field">{{ account.FirstName }} {{ account.Surname }}</div>
              </div>
              <div class="col-lg-6 col-7">
                <label>Account Number</label>
                <div class="info-field">{{ account.ExternalRefNumber }}</div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row h-100 mt-3 mt-lg-0">
              <div class="col-lg-12 col-5">
                <label>Status</label>
                <ng-container [ngSwitch]="account.UserAccountStatus">
                  <div class="info-field">
                    <div *ngSwitchCase="0" class="text-danger">Not Connected</div>
                    <div *ngSwitchCase="1" class="text-success">Connected</div>
                    <div *ngSwitchCase="2" class="text-danger">Not Connected</div>
                    <div *ngSwitchDefault class="text-danger">Already In Use</div>
                  </div>
                </ng-container>
              </div>
              <div class="col-7 d-lg-none">
                <label>Email Address</label>
                <div class="info-field">{{ account.EmailAddress1 }}</div>
              </div>
            </div>
          </div>
          <div class="d-lg-flex d-block justify-content-between w-100 p-2">
            <div class="d-lg-block d-none">
              <label>Email Address</label>
              <div class="info-field">{{ account.EmailAddress1 }}</div>
            </div>
            <div class="mt-3 text-center">
              <ng-container [ngSwitch]="account.UserAccountStatus">
                <div *ngSwitchCase="0">
                  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.create.button'">
                    <a class="btn btn-primary font-weight-bold w-100" [href]="account.EmailAddress2">Create Account</a>
                  </ng-container>
                </div>
                <div *ngSwitchCase="1">
                  <a class="btn btn-secondary font-weight-bold w-100" [href]="account.EmailAddress2">Login</a>
                </div>
                <div *ngSwitchCase="2">
                  <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.create.button'">
                    <a class="btn btn-primary font-weight-bold w-100" [href]="account.EmailAddress2">Create Account</a>
                  </ng-container>
                </div>
                <div *ngSwitchDefault>
                  <i>n/a</i>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="container text-left">
          <a class="btn btn-back btn-black text-white font-weight-bold" [routerLink]="['/login']">Back</a>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #noAccounts>
  <p class="my-2">
    <i>
      We're sorry but an error has occurred. We could not find any accounts for you to choose from.
      <br />
      Please click below to enter your credentials again.
    </i>
  </p>
  <a [routerLink]="['/login']" class="btn btn-primary">Login</a>
</ng-template>
