<div class="d-lg-block d-none">
  <fgb-banner [title]="'challenges.page.title' | translate"></fgb-banner>
</div>

<div class="d-block d-lg-none container my-3"><fgb-member-card></fgb-member-card></div>

<div class="container mt-4">
  <!--Scorecard carousel-->
  <fgbcl-scorecard-carousel
    [icon]="'chevron_right'"
    [showAllView]="false"
    [version2Design]="true"
    [phaseThree]="true"
  ></fgbcl-scorecard-carousel>
  <fgbcl-badge-overview-list [designV2]="true" [phaseThree]="true"></fgbcl-badge-overview-list>
</div>
