<div class="campaign-details-page text-black container">
  <div class="d-flex pt-4">
    <div class="text-primary d-flex align-items-start cursor-pointer back-button" [routerLink]="'/rewards/wallet'">
      <span class="material-icons"> keyboard_arrow_left </span>
      <span class="font-size-14 font-weight-bold">{{ 'campaign.back.to.wallet' | translate }}</span>
    </div>
    <!-- Image -->
    <div class="loyalty-details-image shadow border-0 rounded overflow-hidden p-0">
      <img
        [ngClass]="{
          'image-opacity': currentCampaign.campaignRedemptions_Quantity == 0
        }"
        alt=""
        [src]="currentCampaign.image_URL"
        class="logo"
      />
    </div>
    <div class="back-button icon-placement">
      <ng-container *ngIf="currentCampaign.campaignRedemptions_Quantity == 0">
        <span class="icon material-icons text-grey text-right ml-3"> lock </span>
      </ng-container>
      <ng-container *ngIf="currentCampaign.campaignRedemptions_Quantity > 0">
        <span class="icon material-icons text-success text-right ml-3"> check_circle </span>
      </ng-container>
    </div>
  </div>
  <!-- Name -->
  <div class="text-center font-size-14 font-weight-bold mt-3">{{ currentCampaign.CampaignName }}</div>
  <!-- Expiry -->
  <div class="font-size-12 text-center text-danger pt-lg-2 pt-0">
    {{ 'offer.expiry' | translate }} {{ currentCampaign.Campaign_ExpiryDate | date : 'dd/MM/YYYY' }}
  </div>
  <!-- Description -->
  <hr class="border-top border-disabled my-3" />
  <label class="font-weight-bold pt-2">{{ 'offer.description' | translate }}</label>
  <div class="font-size-14">{{ currentCampaign.PortalDescription }}</div>
</div>
