<div class="d-none d-lg-block">
  <fgb-banner [title]="name$ | async"></fgb-banner>
</div>
<div class="d-block d-lg-none container my-3"><fgb-member-card></fgb-member-card></div>
<fgb-account-nav></fgb-account-nav>

<div class="container">
  <div class="row">
    <div class="col-lg-6 d-none d-lg-block">
      <h5 class="mb-3 mt-lg-0 title-size">{{ 'account.member.card.title' | translate }}</h5>
      <fgb-member-card accountPage="true"></fgb-member-card>
    </div>
    <div class="col-12 col-lg-6">
      <h5 class="my-3 mt-lg-0 title-size">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
