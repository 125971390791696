<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue: keepOrder">
      <h5 class="transaction-date mt-4 mb-3">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-5">
        <div class="card my-3 py-2 px-3" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-left">
              <div class="font-weight-bold text-black transaction-text font-size-12">
                {{ transaction.TransactionDescription }}
              </div>

              <div class="transaction-subtext font-weight-bold font-size-10">
                {{ transaction.TransactionDate | date: 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-right pt-2">
              <div
                class="font-weight-bold transaction-text text-capitalize"
                [ngClass]="transaction.Amount >= 0 ? 'text-success' : 'text-primary'"
              >
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe: (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div>
        <div class="icon material-icons text-grey w-100 text-center">history</div>
        <h5 class="text-grey text-center my-0 font-weight-bold text-uppercase">
          {{ 'no.results.activity' | translate }}
        </h5>
        <p class="text-grey text-center font-size-12">{{ 'no.results.activity.description' | translate }}</p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
