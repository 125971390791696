<fgb-navigation-indicator></fgb-navigation-indicator>

<fgb-mobile-nav-menu></fgb-mobile-nav-menu>
<fgb-nav-bar></fgb-nav-bar>

<ng-container *ifScreenSize="screenSize.Desktop">
  <fgbcl-notification-toast></fgbcl-notification-toast>
</ng-container>

<div id="main">
  <router-outlet></router-outlet>
</div>

<fgb-footer></fgb-footer>
